import React, { Component } from 'react';
import './style.scss';
import classNames from 'classnames';
import 'react-chat-widget/lib/styles.css';
import { connect } from 'react-redux'
import { Widget, addResponseMessage, addUserMessage} from '../../../../../../components/Chat';
import { sendMessage } from '../../../../actions';
import {toastr} from 'react-redux-toastr'
import {findIndex, orderBy} from 'lodash';
import { I18n } from 'react-redux-i18n';

class Chat extends Component {

  state = {count: 0, chatOpened: false}

  componentDidMount() {
    const {is_logined} = this.props;
    this.props.messages.forEach(item => {
    console.log("Chat -> componentDidMount -> item", item)
      if(is_logined){
        addResponseMessage((item.name ? item.name : 'st_' + (item.student_id || '')) + ': ' + item.message)
      } else if(item.student_id == localStorage.getItem('studentId')){
        addUserMessage(item.message)
      }
    });
  }

  handleNewUserMessage = (message) => {
    const {is_logined, items, slide_id, session_id} = this.props;
    let tmp_items = items.map(item => ({
      ...item, 
      order_x: isNaN(parseInt(item.order_x)) ? 0 : parseInt(item.order_x)
    }));
    tmp_items = orderBy(items, ['order_x'], ['asc']);
    console.log("Chat -> handleNewUserMessage -> tmp_items", tmp_items)
    let index= findIndex(tmp_items, ['id', slide_id])

    this.props.sendMessage({
      message,
      role: is_logined ? 'teacher' : 'student',
      session_id,
      slide_index: index,
      name: !is_logined ? localStorage.getItem('session_student_name_' + session_id) : '',
      student_id: is_logined ? null : localStorage.getItem('studentId'),
    })
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.is_proector){
      return;
    }
    if(nextProps.messages.length != this.props.messages.length && !this.state.chatOpened && nextProps.is_logined){
      this.setState({count: this.state.count + nextProps.messages.length - this.props.messages.length});
      nextProps.messages.filter(item => findIndex(this.props.messages, ['id', item.id]) < 0).map(item => {
        toastr.info(I18n.t('newQuestionNotification'), item.message)
      })
    }
  }

  render() {
    const {count} = this.state;
    const {is_logined, is_proector, presentation_mode} = this.props;
    if(is_proector || (is_logined && presentation_mode == 'present')){
      return null;
    }
    return <div className={classNames("ChatWidget", {hideSend: is_logined})}>
      <Widget
        title={is_logined ? I18n.t('titleChatStudentQuestion') : I18n.t('titleChatQuestion')}
        subtitle={false}
        senderPlaceHolder="Message..."
        handleNewUserMessage={this.handleNewUserMessage}
        badge={is_logined && !this.state.chatOpened ? this.state.count : 0} 
        autofocus={false}
        handleQuickButtonClicked={(value) => {
          this.setState({
            chatOpened: value,
            count: value ? 0 : 0
          })
        }}
      />
    </div>
  }
}



const mapStateToProps = (state, ownProps) => ({
  messages: state.presentation.messages,
  slide_id: state.presentation.slide_id,
  items: state.presentation.items,
  is_logined: state.auth.is_logined,
  is_proector: state.auth.is_proector,
  presentation_mode: state.presentation.presentation_mode,
  session_id: state.presentation.session_id,
})

const mapDispatchToProps = {
  sendMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat)