import React, { Component } from 'react';
import './style.scss';
import QuestionForm from './components/QuestionForm';
import { connect } from 'react-redux'
import { setAnswer, getStudentAnswers } from '../../../../../../actions';

class Question extends Component {
  constructor(props) {
    super(props)
    this.timer = null;
  }
  

  componentDidMount(){

    

    this.timer = setInterval(() => {
      const {presentation, slide, session_id, slide_id, is_logined, is_proector} = this.props;

      if(!is_logined && !is_proector){
        return
      }

      try {
        this.props.getStudentAnswers({
          session_id,
          question_id: slide.src,
          pr_id: presentation.id,
        }, slide_id)
      } catch (error) {
        
      }

      
    }, 10 * 1000);
  };

  componentWillUnmount(){
    clearInterval(this.timer);
  }
  

  setAnswer = (answer) => {
    try {
      const {presentation, slide, session_id, slide_id, is_view} = this.props;
      this.props.setAnswer({
        answer,
        pr_id: presentation.id,
        session_id: session_id,
        question_id: slide.src,
        student_id: localStorage.getItem('studentId'),
        student_name: localStorage.getItem('session_student_name_' + session_id) || ''
      }, slide_id, is_view);
    } catch (error) {
      
    }
    
  }

  render() {
    const {slide, is_logined, is_proector, presentation, presentation_mode, is_view} = this.props;
    if(is_logined){
      slide.question.is_answer = is_logined && presentation_mode == 'presentation';
    }
    
    return (
      <div className="Question"> 
        <QuestionForm 
          presentation={presentation} 
          item={slide.question} 
          slide={slide} 
          is_logined={is_logined && presentation_mode == 'presentation'} 
          is_teacher={is_logined}
          is_view={is_view}
          is_proector={is_proector || is_logined && presentation_mode == 'present'} 
          setAnswer={this.setAnswer} 
        />
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  session_id: state.presentation.session_id,
  slide_id: state.presentation.slide_id,
  presentation: state.presentation.presentation,
  is_logined: state.auth.is_logined,
  is_proector: state.auth.is_proector,
  is_view: state.auth.is_view,
  presentation_mode: state.presentation.presentation_mode,
})

const mapDispatchToProps = {
  setAnswer,
  getStudentAnswers
}

export default connect(mapStateToProps, mapDispatchToProps)(Question)

