import RestClient from '../../config/rest-client';
import {toastr} from 'react-redux-toastr'
import { I18n } from 'react-redux-i18n';


export function getPresentation(id) {
  return async (dispatch) => {
    try {
      const result = await RestClient.findOne('presentations', 'view', {id});
      dispatch({type: 'IS_VIEW', data: true});
      dispatch({type: 'SET_PRESENTATION', data: { ...result, status: 1 }});
      return result
    } catch (error) {
      console.log("🚀 ~ file: actions.js ~ line 42 ~ return ~ error", error)
      toastr.error('Error', I18n.t('notFoundPresentation'))
      return null;
    }
  }
  
}

export function setView(data) {
  return (dispatch) => {

  }
}