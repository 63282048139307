import React, { Component } from 'react';
import Slider from "react-slick";
import { connect } from 'react-redux'
import './style.scss';
import Image from './components/Image';
import classNames from 'classnames'
import { setSlide } from '../../../../../../actions';
import {orderBy, findIndex} from 'lodash';
import Question from './components/Question';
import config from '../../../../../../../../config';

class SlidItems extends Component {
  refSlider = React.createRef();
  refSliderItemContainer = React.createRef();
  state = {show: true};
  setSlide = (slide_id) => {
    this.props.setSlide(this.props.session_id, slide_id, this.props.is_view)
  }

  componentDidMount(){
    const {items, slide_id, setHeightSlideItems, is_logined} = this.props;

    let tmp_items = items.map(item => ({
      ...item, 
      order_x: isNaN(parseInt(item.order_x)) ? 0 : parseInt(item.order_x)
    }));

    tmp_items = orderBy(items, ['order_x'], ['asc']);


    let index = findIndex(tmp_items, ['id', slide_id]);

    if(this.refSliderItemContainer.current){
      setHeightSlideItems(this.refSliderItemContainer.current.clientHeight + 20)
    }

    if(this.refSlider.current){
      this.refSlider.current.slickGoTo(index);
    }

    window.addEventListener("resize", this.resize);

    let widget = document.getElementById('rcw-widget-custom');

    if(widget && is_logined){
      widget.style.bottom = '155px';
      widget.style.right = '0px';
    }


  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  resize = () => {
    const {setHeightSlideItems} = this.props;
    if(this.refSliderItemContainer.current){
      setHeightSlideItems(this.refSliderItemContainer.current.clientHeight + 20)
      console.log("SlidItems -> resize -> this.refSliderItemContainer.current.clientHeight", this.refSliderItemContainer.current.clientHeight)
    }
  }

  componentWillReceiveProps(nextProps){
    const {items, slide_id} = nextProps;

    let tmp_items = items.map(item => ({
      ...item, 
      order_x: isNaN(parseInt(item.order_x)) ? 0 : parseInt(item.order_x)
    }));

    tmp_items = orderBy(items, ['order_x'], ['asc']);


    let index = findIndex(tmp_items, ['id', slide_id]);
    let indexOld = findIndex(tmp_items, ['id', this.props.slide_id]);

    if(this.refSlider.current && index != indexOld){
      this.refSlider.current.slickGoTo(index)
    }
  }

  render() {

    const {items, slide_id, is_logined, setHeightSlideItems, is_view} = this.props;
    const {show} = this.state;
    if(!is_logined && !is_view){
      return <div style={{display: 'none'}}>
        {items.filter(_i => _i.type == 'image').map(_i => <img src={config.siteUrl + _i.src} />)}
      </div>;
    }

    const settings = {
      infinite: false,
      slidesToShow: 6,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
      ]
    };

    let tmp_items = items.map(item => ({
      ...item, 
      order_x: isNaN(parseInt(item.order_x)) ? 0 : parseInt(item.order_x)
    }));

    tmp_items = orderBy(items, ['order_x'], ['asc']);

    let prevId = null;
    let nextId = null;
    let currentIndex = null;


    tmp_items.some((item, index) => {
      if(item.id == slide_id) {
        if(index > 0 && tmp_items[index-1].id){
          prevId = tmp_items[index-1].id;
        }

        currentIndex = index;

        if(index + 1 <= tmp_items.length - 1){
          nextId = tmp_items[index+1].id;
        }
        return true;
      }
    });


    return (
      <React.Fragment>
        {(!show && prevId) && <div onClick={() => {this.setSlide(prevId)}} className="nav-slide-btn-left"><i className="fas fa-angle-left"></i></div>}
        {(!show && nextId) && <div onClick={() => {this.setSlide(nextId)}} className="nav-slide-btn-right"><i className="fas fa-angle-right"></i></div>}
        <div className="SlidItems" id="SlidItems" ref={this.refSliderItemContainer}>
          <div className="menu-nav-sliditems" onClick={() => {
            let widget = document.getElementById('rcw-widget-custom');
            if(widget){
              widget.style.bottom = !show ? '155px' : 0;
              widget.style.right = show ? '55px' : 0;
            }
            
            this.setState({show: !show}, () => {
              setHeightSlideItems(this.refSliderItemContainer.current.clientHeight + 20);
              if(this.refSlider.current && currentIndex > -1){
                this.refSlider.current.slickGoTo(currentIndex)
              }
            })
          }}>
            {show ? <i className="fas fa-angle-down"></i> : <i className="fas fa-angle-up"></i>}
          </div>
          {show && <Slider ref={this.refSlider} {...settings}>
            {tmp_items.map((item, index) =>
              <div key={`slider-${index}`} data-key-index={index} data-key-id={item.id} className={classNames("item", {active: slide_id == item.id})}>
                <div className="container-item" onClick={() => {this.setSlide(item.id)}}>
                  {!is_view && <div className="count-item">{index+1}</div>}
                  {!is_view && <div className="count-like"><i className="fas fa-thumbs-up"></i>{item.like_count}</div>}
                  {!is_view && <div className="count-unlike"><i className="fas fa-thumbs-down"></i>{item.unlike_count}</div>}
                  {item.type == 'image' && <Image item={item}/>}
                  {item.type == 'question' && <Question item={item}/>}
                </div>
              </div>
            )}
          </Slider>}
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  items: state.presentation.items,
  slide_id: state.presentation.slide_id,
  is_logined: state.auth.is_logined,
  is_view: state.auth.is_view,
  session_id: state.presentation.session_id,
})

const mapDispatchToProps = {
  setSlide
}

export default connect(mapStateToProps, mapDispatchToProps)(SlidItems);
