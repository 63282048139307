import React, { Component } from 'react';
import { connect } from 'react-redux'
import { isLoaded } from '../../actions';
import FieldInput from '../../components/Fields/FieldInput';
import { I18n } from 'react-redux-i18n';
import './style.scss';
import history from '../../lib/history';
import { getPresentation } from '../Presentation/actions';
import { sendLogin } from './actions';
import PageStart from './components/PageStart/PageStart';
import Spiner from '../../components/Spiner';

class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      code: '', 
      password: '', 
      showPage: props.start_presentation.show, 
      spinner: false,
      data: props.start_presentation,
      is_proector: false,
    }
  }

  
  componentDidMount(){
    this.props.isLoaded();
    this.login({preventDefault: () => {}})
  }

  login = async (e) => {
    e.preventDefault();
    const {code, password, is_proector} = this.state;
    if(code && password){
      // history.push('/presentation/' + this.state.cod);
      this.setState({spinner: true})
      const res = await sendLogin({code, password, is_proector})
      this.setState({spinner: false})
      
      if(res){
        if(res.status){
          history.push('/presentation/' + res.token)
        } else {
          this.setState({
            showPage: true,
            data: res,
          })
        }
      }
    }
  }

  render() {


    const {showPage, spinner, data, is_proector} = this.state;

    if(spinner){
      return <Spiner />
    }


    if(showPage){
      return <PageStart {...data} />
    }


    return (
      <div className="Login">
        <div className="img-fs">
          <img src="/images/New_version_.png" alt="" />
        </div>
        <form onSubmit={this.login} className="form">
         
          <div className="logo">
            <img src="/images/logo_home.png" />
          </div>
          <FieldInput 
            input={{
              onChange: (e) => {
                this.setState({code: e.target.value.replace(/ /g, '')})
              },
              value: this.state.code
            }}
            label={I18n.t('codeLabel')}
            meta={{}}
          />
          <FieldInput 
            input={{
              onChange: (e) => {
                this.setState({password: e.target.value.replace(/ /g, '')})
              },
              value: this.state.password
            }}
            label={I18n.t('passwordLabel')}
            meta={{}}
          />
          <div className='actions'>
            <button onClick={() => {
              if(this.state.cod){
                history.push('/presentation/' + this.state.cod);
              }
              }} className="btn btn-primary">{I18n.t('stPageLoginTitleBtn2')}</button>
              <label className='is_proector'>
                <input type='checkbox' checked={is_proector} onChange={() => {
                this.setState({is_proector: !is_proector})
              }} /> <i class="fa fa-info-circle" title={I18n.t('stPageLoginCheckProectorOpen')} aria-hidden="true"></i>
              </label>
          </div>
          
        </form>
       
      </div>
    );
  }
}



const mapStateToProps = (state, ownProps) => ({
  start_presentation: state.auth.start_presentation
})

const mapDispatchToProps = {
  isLoaded,
  getPresentation,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
