import moment from 'moment'
import React, { Component } from 'react'
import { I18n } from 'react-redux-i18n'
import SocketContext from '../../../../SocketContext';
import './style.scss'


class PageStart extends Component {
  componentDidMount(){
    const {io} = this.context;
    const {presentation_id} = this.props;
    io.socket.request({
      method: 'post',
      url: '/api/auth/redirect_presentation',
      data: {
        presentation_id: presentation_id, 
      }
    });
  }
  render() {
    const {start_date, start_message, name} = this.props;
    return (
      <div className="PageStart">
        <h1>{name}</h1>
        <h3>{I18n.t('startPresentionDateTitle')} {start_date ? start_date : I18n.t('startPresentionDateDefault')}</h3>
        <p>{start_message || I18n.t('startPresentionMessage')}</p>
      </div>
    )
  }
}

PageStart.contextType = SocketContext;

export default PageStart
