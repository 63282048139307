import React, { Component } from 'react';
import PresentationMode from '../PresentationMode';
import Pause from '../Pause';
import QRCode from '../QRCode';
import StudentAnswereds from '../StudentAnswereds';
import './style.scss'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { I18n } from 'react-redux-i18n';

class Actions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
      minimize: false,
    }

    this.refQRCode = React.createRef();
    this.refPause = React.createRef();
    this.refPresentationMode = React.createRef();
  }

  componentDidMount(){
    window.addEventListener('resize', this.updateSize);
    window.setMinimiseActions = this.setMinimiseActions;
  }

  setMinimiseActions = (status) => {
    this.setState({
      minimize: status,
    })
  }


  updateSize = () => {

  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.updateSize)
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  clickVideo = () => {
    const {start, setRootState, videoStream} = this.props;
    if(start){
      setRootState({
        start: false, 
      });
      if(videoStream.current){
        videoStream.current.startVideo();
      }
    } else {
      setRootState({showModalInfoVideoStream: true})
    }
  }
  


  clickProector = () => {
    const {presentation, token_proector} = this.props;

    try {
      const h = window.window.screen.height - 400; 
      const w = window.screen.width - 400; 

      const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
      const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);

      window.open(window.location.origin + '/presentation/' + token_proector, 'proector', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+y+', left='+x);
    
    } catch (error) {
    }
  }

  clickStatistic = () => {
    const {isBlockedSpiner, showStatisticQuestion, slide} = this.props;

    isBlockedSpiner(true);
    showStatisticQuestion({id: slide.id, status: !slide.show_statistic});
  }

  clickAnswer = () => {
    const {isBlockedSpiner, showAnswerQuestion, slide} = this.props;

    isBlockedSpiner(true);
    showAnswerQuestion({id: slide.id, status: !slide.show_answer_true});
  }

  render() {

    const { dropdownOpen, minimize } = this.state;

    const {
      start, 
      slide, 
      session_id, 
      prevId, 
      nextId, 
      toggle,
      setSlide,
      presentation_mode,
      is_view,
    } = this.props;

    if(is_view){
      return (
        <div id="ActionsItems" className="actions col-md-7">
          <div className="items-block">
            <div className="icon-item" onClick={() => {
              if(prevId){
                setSlide(session_id, prevId, is_view);
              }
            }}>
              <i className="fas fa-chevron-left"></i>
            </div>
            <div className="icon-item" onClick={() => {
              if(nextId){
                setSlide(session_id, nextId, is_view);
              }
            }}>
              <i className="fas fa-chevron-right"></i>
            </div>
          </div>
          <StudentAnswereds />
        </div>
      )
    }


    return (
      <div id="ActionsItems" className="actions col-md-7">
        <div className="items-block">
          <PresentationMode ref={this.refPresentationMode} hideEl={minimize} />
          {/* {!minimize && <div className="icon-item" onClick={this.clickVideo}>
            {start && <i className="fas fa-video-slash"></i> || <i className="fas fa-video"></i>}
          </div>} */}
          {(!minimize && slide && slide.type == 'question') && <div className="icon-item" style={{background: slide.show_answer_true ? '#8ffc8d' : 'white'}} onClick={this.clickAnswer}>
            <i className="fas fa-question"></i>
          </div>}
          {(!minimize && slide && slide.type == 'question') && <div className="icon-item" style={{background: slide.show_statistic ? '#8ffc8d' : 'white'}} onClick={this.clickStatistic}>
            <i className="fas fa-chart-bar"></i>
          </div>}
          {!minimize && <div className="icon-item" onClick={this.clickProector}>
            <i className="fas fa-tv"></i>
          </div>}
          <div className="icon-item" onClick={() => {
            if(prevId){
              setSlide(session_id, prevId);
            }
          }}>
            <i className="fas fa-chevron-left"></i>
          </div>
          <div className="icon-item" onClick={() => {
            if(nextId){
              setSlide(session_id, nextId);
            }
          }}>
            <i className="fas fa-chevron-right"></i>
          </div>

          <Pause ref={this.refPause} hideEl={minimize} />
          <QRCode ref={this.refQRCode} hideEl={minimize} />
          
          {minimize && <div style={{marginLeft: 10, marginRight: 10}}>
            <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
              <DropdownToggle>
                <i className="fas fa-bars"></i>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={this.clickProector}><i className="fas fa-tv"></i>{I18n.t('subMenuProector')}</DropdownItem>
                {/* <DropdownItem onClick={this.clickVideo}>{start && <i className="fas fa-video-slash"></i> || <i className="fas fa-video"></i>}{I18n.t('subMenuVideo')}</DropdownItem> */}
                <DropdownItem onClick={this.clickStatistic}><i className="fas fa-chart-bar"></i>{I18n.t('subMenuStatistic')}</DropdownItem>
                <DropdownItem onClick={this.clickAnswer}><i className="fas fa-question"></i>{I18n.t('subMenuAnswer')}</DropdownItem>
                <DropdownItem onClick={() => {
                  if(this.refQRCode.current && this.refQRCode.current.toggle){
                    this.refQRCode.current.toggle()
                  }
                }}><i className="fas fa-info"></i>{I18n.t('subMenuQRCode')}</DropdownItem>
                <DropdownItem onClick={() => {
                  if(this.refPresentationMode.current && this.refPresentationMode.current.handleClick){
                    this.refPresentationMode.current.handleClick()
                  }
                }}> {presentation_mode == 'present' ? <i className="fas fa-user-graduate"></i> : <i className="fas fa-chalkboard-teacher"></i>}{I18n.t('subMenuPresentationMode')}</DropdownItem>
                <DropdownItem onClick={() => {
                  if(this.refPause.current && window.startModalPause){
                    window.startModalPause();
                  }
                }}><i className="fas fa-pause"></i>{I18n.t('subMenuPause')}</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>}
          <div className="icon-item" onClick={() => {
            toggle();
            // closePresentation(session_id);
          }}>
            <i className="fas fa-times"></i>
          </div>
        </div>
        <StudentAnswereds />
        
        
      </div>
    );
  }
}

export default Actions;
