import React, { Component } from 'react';
import classNames from 'classnames';
import decodeHTMLEntities from '../../../../../../../../../../../../lib/decodeHTMLEntities';
import ReactTooltip from "react-tooltip";
import { I18n } from 'react-redux-i18n';

class Answer extends Component {
  render() {
    const arrayChar = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
    const {keyAnswer, is_logined, onChange, is_proector, is_view, presentation, question_aswers_all, is_answer, index, item, slide, count_answers, value} = this.props;

    let procent = count_answers && 100/count_answers || 0;

    if(is_proector && !slide.show_statistic){
      procent = 0;
    }

    const students_answer = question_aswers_all.filter(_i => _i.answer == keyAnswer).map(_i => _i.student_name || I18n.t('titleNotName'));

    return (
      <React.Fragment>
        {(students_answer.length > 0 && is_logined) && <ReactTooltip id={"answered-stydent-" + keyAnswer} type="error">
          <span>{students_answer.join(', ')}</span>
        </ReactTooltip>}

        <div
          data-tip 
          data-for={"answered-stydent-" + keyAnswer}
          className={classNames("custom-control custom-radio question-answer-item", {
            'is-view-stat': is_logined || is_proector,
            'answer-false': (presentation.show_student_answer && !is_logined || is_proector || is_logined || is_view) && is_answer && keyAnswer != item.correct_answer && value == keyAnswer && !is_logined,
            'answer-true': (presentation.show_student_answer && !is_logined || is_proector || is_logined || is_view) && ((is_logined && keyAnswer == item.answers.correct_answer) || (is_proector && slide.show_answer_true && keyAnswer == item.answers.correct_answer ) || (is_answer && keyAnswer == item.answers.correct_answer && !is_logined)),
          })}
        >
          {(is_logined || is_proector) && <div className="answer_procent">{(procent * slide.question_aswers_all.filter(q => q.answer == keyAnswer).length).toFixed(0)}%</div>}
          <input type="radio" id={'answer-' + item.id + '-' + index} name="answer" value={keyAnswer} onChange={onChange} checked={value == keyAnswer} className="custom-control-input"/>
          <label className="custom-control-label" htmlFor={'answer-' + item.id + '-' + index}>
            <div className="static-block">
              <div className="progress" style={{width: `calc(${procent * slide.question_aswers_all.filter(q => q.answer == keyAnswer).length}% - 39px)`}}/>
            </div>
            <div className="lb-content">
              <div className="char">
                {arrayChar[index]}
              </div><div>{decodeHTMLEntities(item.answers[keyAnswer])}</div>
            </div>
            
          </label>
        </div>
      </React.Fragment>
    );
  }
}

export default Answer;
