import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './style.scss';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n';
import history from '../../lib/history';
import config from '../../config';
import {orderBy} from 'lodash';
import { closePresentation, setSlide, showStatisticQuestion, showAnswerQuestion } from '../Presentation/actions';
import QRCode from './components/QRCode';
import Like from './components/Like';
import SoundVideo from './components/SoundVideo/SoundVideo';
import { isBlockedSpiner } from '../../actions';
import Pause from './components/Pause';
import QRCodeModal from './components/QRCodeModal';
// import SoundVideo from './components/SoundVideo/SoundVideo';

import PresentationMode from './components/PresentationMode';
import StudentAnswereds from './components/StudentAnswereds';
import { changeModalFeetback } from '../Feetback/actions';
import Actions from './components/Actions/Actions';

class Header extends Component {
  constructor(props) {
    super(props);
    this.videoStream = React.createRef();
    this.state = {
      isOpen: false,
      menu: true,
      start: false,
      showModalInfoVideoStream: false
    };
  }
  
  toggle = () => {
    this.setState({isOpen: !this.state.isOpen})
  }

  componentWillReceiveProps(nextProps){
    const {is_proector} = nextProps;
    if(is_proector){
      const el = document.getElementById('layout');
      if(el){
        el.style.height = '100%';
      }
    }
  }

  componentDidMount(){
    const {is_proector} = this.props;
    if(is_proector){
      const el = document.getElementById('layout');
      if(el){
        el.style.height = '100%';
      }
    }
  }

  toggledMenu = () => {
    const {menu} = this.state;
    const el = document.getElementById('layout');
    
    if(!menu){
      if(el){
        el.style.height = 'calc(100% - 75px)';
      }
    } else {
      if(el){
        el.style.height = '100%';
      }
    }
    this.setState({menu: !menu});

    window.postMessage({
      appHeaderEvent: !menu
    })

  }

  viewCode = () => {
    const {presentation} = this.props;
    let tmp = presentation.code;
    let result = '';

    while (tmp.length/3 >= 2) {
      result += tmp.slice(0, 3) + '  ';
      tmp = tmp.slice(3, tmp.length)
    }
    return result + tmp;
  }

  render() {
    const {isOpen, menu, start, showModalInfoVideoStream} = this.state;
    const {presentation, token_proector, is_logined, session_id, items, slide_id, is_proector, count_student, presentation_mode, is_view} = this.props;

    if(is_proector){
      return null;
    }

    let slide = null;

    let prevId = null;
    let nextId = null;

    let tmp_items = items.map(item => ({
      ...item, 
      order_x: isNaN(parseInt(item.order_x)) ? 0 : parseInt(item.order_x)
    }));

    tmp_items = orderBy(items, ['order_x'], ['asc']);

    

    tmp_items.some((item, index) => {
      if(item.id == slide_id) {
        slide = item;
        if(index > 0 && tmp_items[index-1].id){
          prevId = tmp_items[index-1].id;
        }

        if(index + 1 <= tmp_items.length - 1){
          nextId = tmp_items[index+1].id;
        }
        return true;
      }
    });

    

    return (
      <React.Fragment>
        {menu && <div className="Header" id="header">
          <div className="container" style={{maxWidth: '100%', overflow: 'unset'}}>
          <Navbar light expand="md">
            <div className="left-block col-md-5">
              {(!presentation.id || is_view) && <div className="logo" to="/"><img src="/images/logo.png" alt="" /></div>}
              {presentation.id && !is_view && <div className="title">{I18n.t('txtView')}: {this.viewCode()}</div>}
              {is_logined && presentation.id && <div className="title">{I18n.t('txtStudentCount')}: {count_student}</div>}
            </div>
            {(is_logined || is_view) && presentation.id ? <Actions
              start={start}
              slide={slide}
              presentation={presentation}
              session_id={session_id}
              is_view={is_view}
              prevId={prevId}
              presentation_mode={presentation_mode}
              token_proector={token_proector}
              nextId={nextId}
              showAnswerQuestion={showAnswerQuestion}
              videoStream={this.videoStream}
              showStatisticQuestion={showStatisticQuestion}
              toggle={this.toggle}
              isBlockedSpiner={this.props.isBlockedSpiner}
              setSlide={this.props.setSlide}
              setRootState={(data) => this.setState(data)}
            /> : null}
            {!is_logined && presentation.id && !is_view && <div className="actions col-md-6">
              <Like slide={slide} />
              <QRCode />
              {presentation.download_presentation && <a href={config.siteUrl + '/api/download/presentation?session_id=' + session_id} target="_blank" className="icon-item">
                <i className="fas fa-download"></i>
              </a>}
              {presentation.download_slide && <a href={config.siteUrl + '/api/download/presentation?session_id=' + session_id + '&slide_id=' + slide_id} target="_blank" className="icon-item">
                <i className="fas fa-file-download"></i>
              </a>}
            </div>}
          </Navbar>
          </div>
        </div>}
        {/* <SoundVideo ref={this.videoStream} /> */}
        {presentation && presentation.id && <div className="control-menu-action" onClick={this.toggledMenu}>
          {menu ? <i className="fas fa-angle-up"></i> : <i className="fas fa-angle-down"></i>}
        </div>}
        <Modal isOpen={isOpen} toggle={this.toggle} className={''}>
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <ModalBody>
            {I18n.t('messageClosePresentation')}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => {
              this.toggle();
              // if(this.state.start){
              //   if(this.videoStream.current){
              //     this.videoStream.current.startVideo();
              //   }
              // }
              // this.props.closePresentation(session_id);
              this.props.changeModalFeetback(true);
              
            }}>{I18n.t('btnOkPresentation')}</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>{I18n.t('btnCancelPresentation')}</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showModalInfoVideoStream} toggle={() => {this.setState({showModalInfoVideoStream: false})}} className={''}>
          <ModalHeader toggle={() => {this.setState({showModalInfoVideoStream: false})}}></ModalHeader>
          <ModalBody>
            {I18n.t('messageVideoChat')}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => {
              this.setState({
                start: true, 
                showModalInfoVideoStream: false
              });
              if(this.videoStream.current){
                this.videoStream.current.startVideo();
              }
            }}>{I18n.t('btnOkVideoChat')}</Button>{' '}
            <Button color="secondary" onClick={() => {this.setState({showModalInfoVideoStream: false})}}>{I18n.t('btnCancelVIdeoChat')}</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  is_logined: state.auth.is_logined,
  is_proector: state.auth.is_proector,
  is_view: state.auth.is_view,
  profile: state.auth.profile,
  presentation: state.presentation.presentation,
  session_id: state.presentation.session_id,
  items: state.presentation.items,
  slide_id: state.presentation.slide_id,
  count_student: state.presentation.count_student,
  presentation_mode: state.presentation.presentation_mode,
  token_proector: state.presentation.token_proector,
})

const mapDispatchToProps = {
  closePresentation,
  isBlockedSpiner,
  changeModalFeetback,
  setSlide
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)

