import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPresentation, isProector } from './actions';
import Start from './components/Start/Start';
import View from './components/View';
import Spiner from '../../components/Spiner';
import StartProector from './components/StartProector/StartProector';
import { Beforeunload, useBeforeunload } from 'react-beforeunload';
import { Prompt } from 'react-router'
import StudentName from './components/StudentName/StudentName';
import PresentationMode from './components/PresentationMode/PresentationMode';
import jwt_decode from "jwt-decode";

class Presentation extends Component {
  constructor(props) {
    super(props)
    this.state = {loaded: false}
    if(props.proector){
      this.props.isProector(true)
    }
  }
  
  componentDidMount = async() => {
    var decoded = jwt_decode(this.props.match.params.id) || {};
    if(decoded.is_proector){
      this.props.isProector(true)
    }

    const result = await this.props.getPresentation(decoded.code, decoded.user_id);
    this.setState({loaded: true});

    setTimeout(() => {
      const {is_logined} = this.props;
      if(is_logined){
        this.props.getPresentation(decoded.code, decoded.user_id);
      }
    }, 1200)
 
   
  };

  render() {
    const {loaded} = this.state;

    const {is_proector, presentation, session_id, is_logined} = this.props;

    if(!loaded){
      return <Spiner />;
    }
    
    return (
      <React.Fragment>
        <StudentName />
        {!is_proector && <PresentationMode />}
        {is_proector ? <StartProector/> : <Start />}
        <View />
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  is_proector: state.auth.is_proector,
  is_logined: state.auth.is_logined,
  presentation: state.presentation.presentation,
  session_id: state.presentation.session_id,
})

const mapDispatchToProps = {
  getPresentation,
  isProector
}

export default connect(mapStateToProps, mapDispatchToProps)(Presentation)

