import React, { Component } from 'react';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux'
import { setPresentationMode } from '../../../../actions';

class PresentationMode extends Component {

  setMode = (presentation_mode) => {
    const {setPresentationMode, session_id, presentation} = this.props;

    setPresentationMode({
      session_id,
      presentation_mode,
      pr_id: presentation.id
    })
  }

  render() {
    const {is_logined, presentation_mode, presentation} = this.props;
    if(presentation_mode){
      return null;
    }

    if(!is_logined){
      return(
      <div className="PresentationMode">
        <div className="block">
          <h4>{presentation.name}</h4>
          <div className="text">{I18n.t('titlePresentationMessagePlase')}</div>
        </div>
      </div>)
    }


    return (
      <div className="PresentationMode">
        <div className="block">
          <div className="text">{I18n.t('titlePresentationMessage')}</div>
          <div className="actions">
            <button onClick={() => {this.setMode('present')}} className="btn btn-primary">{I18n.t('btnPresent')}</button>
            <button onClick={() => {this.setMode('presentation')}} className="btn btn-primary">{I18n.t('btnPresentation')}</button>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  is_logined: state.auth.is_logined,
  presentation_mode: state.presentation.presentation_mode,
  session_id: state.presentation.session_id,
  presentation: state.presentation.presentation,
})

const mapDispatchToProps = {
  setPresentationMode
}

export default connect(mapStateToProps, mapDispatchToProps)(PresentationMode)
