import classNames from 'classnames';
import { orderBy } from 'lodash';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Swipeable } from 'react-swipeable';
import View from '../Presentation/components/View';
import Image from '../Presentation/components/View/components/Slide/components/Image';
import Question from '../Presentation/components/View/components/Slide/components/Question';
import { getPresentation, setView } from './actions';
import jwt_decode from "jwt-decode";
import { I18n } from 'react-redux-i18n';
import {toastr} from 'react-redux-toastr'

class ViewPresentation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      heightSlideItems: 0,
      items: [],
      presentation: {}
    }
  }

  setHeightSlideItems = (heightSlideItems) => {
    this.setState({heightSlideItems})
  }
  
  async componentDidMount(){
    try {
      var decoded = jwt_decode(this.props.match.params.id) || {};
      if(!decoded.is_view){
        toastr.error('Error', I18n.t('notFoundPresentation'))
        return null;
      }
      
      const data = await this.props.getPresentation(decoded.id);
      this.setState({
        ...data,
        loaded: true
      })
    } catch (error) {
      toastr.error('Error', I18n.t('notFoundPresentation'))
    }
  }


  setSlide(){

  }



  render() {
    const {
      loaded, 
      items, 
      slide_id, 
      session_id, 
      is_logined, 
      heightSlideItems, 
      presentation
    } = this.state;
    let slide = null;


    if(!loaded){
      return null;
    }

    return <View />

    let prevId = null;
    let nextId = null;

    let tmp_items = items.map(item => ({
      ...item, 
      order_x: isNaN(parseInt(item.order_x)) ? 0 : parseInt(item.order_x)
    }));

    tmp_items = orderBy(items, ['order_x'], ['asc']);

    

    tmp_items.some((item, index) => {
      if(item.id == slide_id) {
        slide = item;
        if(index > 0 && tmp_items[index-1].id){
          prevId = tmp_items[index-1].id;
        }

        if(index + 1 <= tmp_items.length - 1){
          nextId = tmp_items[index+1].id;
        }
        return true;
      }
    });

    if(!slide){
      return null;
    }

    if(slide.type == 'image'){
      document.getElementById('layout').style.background = '#e5e5e5';
    } else {
      document.getElementById('layout').style.background = 'white';
    }

    const config = {
      delta: 30,                             // min distance(px) before a swipe starts
      preventDefaultTouchmoveEvent: true,   // preventDefault on touchmove, *See Details*
      trackTouch: true,                      // track touch input
      trackMouse: false,                     // track mouse input
      rotationAngle: 20,                      // set a rotation angle
    }
    return (
      <div className="View" id="view-container">
        <Swipeable
          className={classNames("Slide", 'col-md-12')}
          onSwipedLeft={() => {
            if(nextId){
              this.props.setSlide(session_id, nextId);
            }
          }}
          onSwipedRight={() => {
            if(prevId){
              this.props.setSlide(session_id, prevId);
            }
          }}
          {...config}
        >
          {slide.type =='image' && <Image 
            // setIsDrawPaint={this.setIsDrawPaint} 
            // setSketch={this.props.setSketch} 
            heightSlideItems={heightSlideItems} 
            slide_id={slide_id} 
            slide={slide} 
            presentation={presentation} 
            is_logined={is_logined} 
          />}
          {slide.type =='question' && <Question slide={slide} />}
        </Swipeable>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
  getPresentation,
  setView,
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewPresentation)
