import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.scss';
import { I18n } from 'react-redux-i18n';
import Spiner from '../../../../components/Spiner';
import { startPresentation } from '../../actions';

class Start extends Component {
  render() {
    const {start, presentation, count_student, session_id} = this.props;
    if(start){
      return null;
    }

    return (
      <div className="StartPage">
        <h2>{presentation.name}</h2>
        <h4>{I18n.t('msdThisEctuvutuStatistigSoom')}</h4>
        <div className="active-student">
          <div>{count_student} students joined this session</div>
        </div>
        <div className="spiner">
          <Spiner/>
        </div>
        
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  start: state.presentation.start,
  presentation: state.presentation.presentation,
  count_student: state.presentation.count_student,
  session_id: state.presentation.session_id,
})

const mapDispatchToProps = {
  startPresentation
}

export default connect(mapStateToProps, mapDispatchToProps)(Start)

