import React, { Component } from 'react';
import { connect } from 'react-redux';
import socketIOClient from 'socket.io-client';
import sailsIOClient from 'sails.io.js';
import config from './config';
import { addResponseMessage} from './components/Chat';
import history from './lib/history';
import SocketContext from './SocketContext';

class SocketComponent extends Component {

  constructor(props) {
    super(props)
    this.clientSocket = null;
    this.state = {
      socket: null,
    }
    this.reconnectTimer = null;
  }

  userOnline = () => {
    const {is_proector, is_logined, presentation, session_id, is_view} = this.props;
    if(presentation.id && !is_view){
      this.clientSocket.socket.request({
        method: 'post',
        url: '/api/auth/online',
        data: {
          id: presentation.id,
          student: !(is_proector || is_logined),
          session_id: session_id
        }
      }, (body, response) => {
    
      });
    }
  }

  componentWillUnmount(){
    clearInterval(this.reconnectTimer);
  }

  componentWillMount(){
    this.clientSocket = sailsIOClient(socketIOClient);
    this.clientSocket.sails.url = config.apiUrl;
    this.clientSocket.sails.autoConnect = true;
    this.clientSocket.sails.reconnection = true;
    this.clientSocket.socket.on('connect',  (socket) => {
    
      const {is_proector, is_logined, is_view} = this.props;

      if(this.props.presentation.id){
        this.clientSocket.socket.request({
          method: 'post',
          url: '/api/auth/socket_init',
          data: {
            id: this.props.presentation.id,
            student: !(is_proector || is_logined),
            session_id: this.props.session_id,
            is_view,
          }
        }, (body, response) => {
      
        });
      }

      this.reconnectTimer = setInterval(() => {
        this.userOnline();
      }, 10 * 1000);

    });

    this.clientSocket.socket.on('reconnect', () => {
      const {is_proector, is_logined, is_view} = this.props;
      if(this.props.presentation.id){
        this.clientSocket.socket.request({
          method: 'post',
          url: '/api/auth/socket_init',
          data: {
            id: this.props.presentation.id,
            student: !(is_proector || is_logined),
            session_id: this.props.session_id,
            is_view,
          }
        }, (body, response) => {
      
        });
      }
    });
    
    this.clientSocket.socket.on('coun_student', (data) => {
      console.log("SocketComponent -> componentWillMount -> data", data)
      const {presentation} = this.props;
      this.props.dispatch({type: 'IS_BLOCKED_SPINER', data: false})

      if(presentation.id == data.presentation){
        this.props.dispatch({type: 'SET_PRESENTATION_COUNT_STUDENT', data: data.count})
      }
      
    });
    
    this.clientSocket.socket.on('presentation_mode', (data) => {
      this.props.dispatch({type: 'SET_PRESENTATION_MODE', data})
    });
    
    this.clientSocket.socket.on('redirect_presentation', (data) => {
      if(data.token_student){
        window.location.href = '/presentation/' + data.token_student
      }
    });

    this.clientSocket.socket.on('change_question', (data) => {
      console.log("🚀 ~ file: SocketComponent.jsx ~ line 104 ~ SocketComponent ~ this.clientSocket.socket.on ~ data", data)
      // this.props.dispatch({type: 'SET_PRESENTATION_MODE', data})
      this.props.dispatch({type: 'SET_UPDATE_QUESTION_ITEM', slide_id: data.slide_id, data: data.content})
    });
    
    this.clientSocket.socket.on('progress', (data) => {
    console.log("SocketComponent -> componentWillMount -> data", data)
      const {presentation, is_proector, is_logined} = this.props;
      this.props.dispatch({type: 'IS_BLOCKED_SPINER', data: false})
      if(presentation.id == data.pr_id){
        if(data.is_closed){
          this.props.dispatch({type: 'PRESENTATION_CLEAR'});
          if(is_proector || is_logined){
            window.close()
          }
          history.push('/');
          return;
        }
        this.props.dispatch({type: 'SET_PRESENTATION_PROGRESS', data});
      }
    });

    this.clientSocket.socket.on('message', (data) => {
      const {presentation, is_logined, presentation_mode} = this.props;
      this.props.dispatch({type: 'IS_BLOCKED_SPINER', data: false})
      if(presentation.id == data.pr_id){
        this.props.dispatch({type: 'ADD_PRESENTATION_MESSAGE', data});
        if(is_logined && presentation_mode == 'presentation'){

          addResponseMessage((data.name ? data.name : 'st_' + (data.student_id || '')) + ': ' + data.message)
        }
        
      }
    });

    this.clientSocket.socket.on('show_statistic_question', (data) => {
      const {presentation, is_logined, is_proector} = this.props;
      this.props.dispatch({type: 'IS_BLOCKED_SPINER', data: false})

      if(is_proector || is_logined){
        this.props.dispatch({type: 'SHOW_PRESENTATION_QUESTION_STATISTIC', data});
      }
      
    });


    this.clientSocket.socket.on('show_info_proector', (data) => {
      const {is_proector} = this.props;
      if(is_proector){
        this.props.dispatch({type: 'SET_SHOW_MODAL_INFO', data})
      }
    });

    this.clientSocket.socket.on('show_answer_question', (data) => {
      const {presentation, is_logined, is_proector} = this.props;
      this.props.dispatch({type: 'IS_BLOCKED_SPINER', data: false})
     
      if(is_proector || is_logined){
        this.props.dispatch({type: 'SHOW_PRESENTATION_QUESTION_ANSWER', data});
      }
      
    });
    
    this.clientSocket.socket.on('student_answer', (data) => {
      const {presentation, is_logined, is_proector} = this.props;
      this.props.dispatch({type: 'IS_BLOCKED_SPINER', data: false})
     
      if(is_proector || is_logined){
        this.props.dispatch({type: 'PRESENTATION_STUDENT_ANSWER', data});
      }
      
    });

    this.clientSocket.socket.on('like', (data) => {
      this.props.dispatch({type: 'IS_BLOCKED_SPINER', data: false})
      
      this.props.dispatch({type: 'PRESENTATION_ITEM_LIKE', data});
    });

    this.clientSocket.socket.on('set_time_limit_question', (data) => {
      console.log("🚀 ~ file: SocketComponent.jsx ~ line 184 ~ SocketComponent ~ this.clientSocket.socket.on ~ data", data)
      localStorage.setItem('timer_question_' + data.slide_id, data.timeSave)
    });

    

    this.clientSocket.socket.on('disconnect', () => {
      this.props.dispatch({type: 'IS_BLOCKED_SPINER', data: false})

    });
  }

  componentWillReceiveProps(nextProps){

    if((nextProps.presentation.id != this.props.presentation.id || nextProps.session_id != this.props.session_id) && this.clientSocket.socket.isConnected()) {
      const {is_proector, is_logined, is_view} = nextProps;
      this.clientSocket.socket.request({
        method: 'post',
        url: '/api/auth/socket_init',
        data: {
          id: nextProps.presentation.id,
          student: !(is_proector || is_logined),
          session_id: nextProps.session_id,
          is_view
        }
      }, (body, response) => {
    
      });
    }

    // this.clientSocket.socket.request({
    //   method: 'get',
    //   url: '/api/auth/socket_init',
    //   headers: this.header
    // }, (body, response) => {
  
    // });
  }
  
  render() {
    return <SocketContext.Provider value={{
      io: this.clientSocket
    }}>
      {this.props.children}
    </SocketContext.Provider>
  }
}

const mapStateToProps = (state, ownProps) => ({
  presentation: state.presentation.presentation,
  session_id: state.presentation.session_id,
  is_logined: state.auth.is_logined,
  is_proector: state.auth.is_proector,
  is_view: state.auth.is_view,
  presentation_mode: state.presentation.presentation_mode,
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps)(SocketComponent)

