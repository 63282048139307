import React, { Component } from 'react';

class Question extends Component {
  render() {
    const {item} = this.props;

    const text = item.question.question.replace(/<[^>]*>/g, '');
    if(!text){
      let regex = /<img.*?src="(.*?)"/;
      let src = regex.exec( item.question.question )[1];
      return <div className="Question">
        <img src={src} />
      </div>
    }


    return (
      <div className="Question"> {text.replace(/<[^>]*>/g, '').substring(0, 120)}{text.length > 120 ? '...' : ''} </div>
    );
  }
}

export default Question;
