import React, { Component } from 'react';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import { findIndex } from 'lodash'
import classNames from 'classnames';
import moment from 'moment'
import decodeHTMLEntities from '../../../../../../../../../../lib/decodeHTMLEntities';
import Answer from './components/Answer/Answer';
import Question from './components/Question/Question';
import SocketContext from '../../../../../../../../../../SocketContext';

class QuestionForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      second: 0,
    }
    this.time = null;
  }

  componentWillReceiveProps(nextProps){

    const { item, is_logined, slide, is_proector } = nextProps;


    if(nextProps.item.id != this.props.item.id && nextProps.item.id){
      this.setState({value: '', second: 0});
      if(nextProps.presentation.time_limit_check && (!item.is_answer || is_logined)){
        clearInterval(this.time);

        let timeSave = moment().unix() + nextProps.presentation.time_limit_question * 60;
  
        localStorage.setItem('timer_question_' + slide.id, timeSave)

        if(timeSave - moment().unix() > 0){
          this.setState({
            second: nextProps.presentation.time_limit_question * 60
          }, () => {
            this.startTimer();
          });
        } else {
          if(!is_logined && !is_proector && !item.is_answer){
            this.props.setAnswer('');
          }
        }
      }
    }
  }

  componentDidMount(){
    const {presentation, item, slide, is_logined, is_proector} = this.props;
    if(presentation.time_limit_check && (!item.is_answer || is_logined)){

      let timeSave = localStorage.getItem('timer_question_' + slide.id)

      if(!timeSave){
        timeSave = moment().unix() + presentation.time_limit_question * 60;
        localStorage.setItem('timer_question_' + slide.id, timeSave)
      } else {
        timeSave = parseInt(localStorage.getItem('timer_question_' + slide.id));
      }

      if(timeSave - moment().unix() > 0){
        // this.startTimer();
        this.setState({
          second: timeSave - moment().unix()
        }, () => {
          this.startTimer();
        });
      } else {
        if(!is_logined && !is_proector && !item.is_answer){
          this.props.setAnswer('');
        }
      }
    }
  }

  componentWillUnmount(){
    clearInterval(this.time);
  }

  getTime = (seconds) => {
    var sec_num = parseInt(seconds, 10);
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
  }


  startTimer = () => {
    
    clearInterval(this.time);
    this.time = setInterval(() => {
      const {is_logined, is_teacher, is_proector, item, slide, presentation} = this.props;
      let timeSave = localStorage.getItem('timer_question_' + slide.id) || 0;
      let second = timeSave - moment().unix();
      const {io} = this.context;

      if(is_teacher){
        io.socket.request({
          method: 'post',
          url: '/api/auth/socket_message',
          data: {
            presentation_id: presentation.id, 
            type: 'set_time_limit_question',
            data: {
              timeSave, 
              slide_id: slide.id,
            }
          }
        });
      }

      if(second <= 0){
        clearInterval(this.time);
        if(!is_logined && !is_proector && !item.is_answer){
          this.props.setAnswer('');
        }
        this.setState({second: 0})
      } else {
        this.setState({second})
      }
      
    }, 1000);
  }

  onChange = (e) => {
    const { item } = this.props;

    let is_answer = item.is_answer;

    if(is_answer){
      return;
    }
    this.setState({value: e.target.value});
    
  }

  setAnswer = (value='') => {
    clearInterval(this.time);
    this.setState({
      second: 0,
    })
    this.props.setAnswer(value);
  }

  render() {
    const { item, is_logined, is_proector, slide, presentation, is_view } = this.props;

    let is_answer = item.is_answer;

    let { value, second } = this.state;

    if(is_answer){
      value = item.student_answer;
    }

    let answers = {};

    Object.keys(item.answers).forEach(key => {
      if(item.answers[key] != '' && item.answers[key] != null && key.indexOf('answer_') > -1){
        answers[key] = item.answers[key];
      }
    });


    const description = item.description || I18n.t('msgExplanationIsNotProvided');

    let count_answers = Array.isArray(slide.question_aswers_all) ? slide.question_aswers_all.filter(i => !!i.answer).length : 0;
    

    return (
      <div className="QuestionForm">
        {(is_logined || (presentation.time_limit_check)) && <div className="answer-count-student">
          {is_logined ? <div>{I18n.t('txtCountAnswerStudent')}: {count_answers}</div> : <div/>}
          <div>{I18n.t('txtTimeAnswerStudent')}: {this.getTime(second)}</div>
        </div>}
        
        <Question question={item.question} slide_id={slide.id} />
        
       
        <div className="question-answer">
          {answers ? Object.keys(answers).map((key, index) => (
            <Answer 
              item={item} 
              key={'answer-' + item.id + '-' + index}
              keyAnswer={key}
              is_logined={is_logined}
              is_view={is_view}
              is_proector={is_proector}
              presentation={presentation}
              is_answer={is_answer}
              index={index}
              slide={slide}
              count_answers={count_answers}
              value={value}
              question_aswers_all={slide.question_aswers_all}
              onChange={this.onChange}
            />
          )) : null}
        </div>
        {is_proector ? null : !is_answer && <div className="answer-btn">
            <button className={classNames("btn btn-primary", {disabled: !this.state.value})} disabled={!this.state.value} onClick={() => {
              if(this.state.value != ''){
                this.setAnswer(this.state.value);
              }
              
            }}>{I18n.t('btnSubmitQuestion')}</button>
        </div>}
       {(is_answer && presentation.show_student_desc_question || is_logined) && <div className="question-content" style={{marginTop: '20px'}} dangerouslySetInnerHTML={{__html: description}}></div>}
      </div>
    );
  }
}

QuestionForm.contextType = SocketContext;

export default QuestionForm
