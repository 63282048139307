import React, { Component } from 'react';
import './style.scss';
import config from '../../../../../../../../../../config';

class Image extends Component {
  render() {
    const {item} = this.props;
    return (
      <div className="ImageItem" style={{backgroundImage: `url(${config.siteUrl + item.src})`}}>
        {/* <img src={item.src} /> */}
      </div>
    );
  }
}

export default Image;
