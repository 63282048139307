import React, { Component } from 'react';
import classNames from 'classnames';
import './FieldStars.scss';
import ReactStars from "react-rating-stars-component";

class FieldStars extends Component {
  render() {
    const {input, label, type, placeholder, className, meta: {error, touched}} = this.props;
    return (
      <div className={classNames("form-group", 'FieldStars', {'error': touched && error}, className)}>
        {label && <label>{label}</label>}
        <ReactStars
          {...input}
          count={5}
          size={32}
          activeColor="#ffd700"
        />
        {touched && ((error && <div className="invalid-feedback">{error}</div>))}
         
      </div>
    );
  }
}


export default FieldStars;
