import React, { Component } from 'react';
import { connect } from 'react-redux'
import { setPause } from '../../../actions';
import { Button, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { I18n } from 'react-redux-i18n';
import FieldInput from '../../../components/Fields/FieldInput';
import { Field, reduxForm } from 'redux-form'
import moment from 'moment';
import { required, numericality } from 'redux-form-validators'

class Pause extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      minutes: 1,
      error: ''
    }
  }
  
  submit = (data) => {
    const {session_id} = this.props;
    this.props.setPause(session_id, {
      pause_time: data.minutes * 60,
      pause_date: moment().unix()
    })
    this.setState({show: false})
  }

  startModal = () => {
    this.setState({show: true})
  }

  componentDidMount = () => {
    window.startModalPause = this.startModal;
  };
  

  render() {
    const {show, minutes, error} = this.state;
    const {session_id, handleSubmit, hideEl} = this.props;

    return (
      <React.Fragment>
        <Modal isOpen={show} toggle={() => {this.setState({show: false})}} className={''}>
          <ModalHeader toggle={() => {this.setState({show: false})}}>{I18n.t('titlePause')}</ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit(this.submit)}>
              <Field 
                name="minutes" 
                validate={[
                  required(),
                  numericality({
                    int: true,
                    '>=': 1,
                    '<=': 30,
                    // msg: { 
                    //   '>=': I18n.t('txtErrorInputPause1'),
                    //   '<=': I18n.t('txtErrorInputPause2'),
                    // },
                  }),
                ]} 
                component={FieldInput}  
                label={I18n.t('inputTitlePause')} 
              />
              {/* <FieldInput 
                input={{
                  onChange: (e) => {
                    this.setState({
                      minutes: e.target.value, 
                      error: isNaN(parseInt(e.target.value, 10)) ? I18n.t('txtErrorInputPause') : ''
                    });

                  },
                  value: minutes
                }}
                meta={{
                  touched: true,
                  error
                }}
                label={I18n.t('inputTitlePause')}
              /> */}
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSubmit(this.submit)}>{I18n.t('btnOkPause')}</Button>{' '}
            <Button color="secondary" onClick={() => {this.setState({show: false})}}>{I18n.t('btnCancelPause')}</Button>
          </ModalFooter>
        </Modal>
        {hideEl ? null : <div className="icon-item" onClick={this.startModal}>
          <i className="fas fa-pause"></i>
        </div>}
      </React.Fragment>
      
    );
  }
}

const PauseForm = reduxForm({
  form: 'pause_form',
})(Pause)

const mapStateToProps = (state, ownProps) => ({
  session_id: state.presentation.session_id,
})

const mapDispatchToProps = {
  setPause
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(PauseForm)
