import React, { Component } from 'react';
import './SizeInput.scss';

class SizeInput extends Component {
  render() {
    const {size, onChange} = this.props
    return (
      <div className="SizeInput">
        
        {/* <div className="actions"> */}
          <div className="item-step" onClick={() => onChange(size + 1)}>
            <i className="fas fa-chevron-up"></i>
          </div>
          <div className="text">{size}</div>
          <div className="item-step" onClick={() => {
            if(size == 1){
              return;
            }
            onChange(size - 1)
          }}>
            <i className="fas fa-chevron-down"></i>
          </div>
        {/* </div> */}
      </div>
    );
  }
}

export default SizeInput;
