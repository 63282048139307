import { combineReducers } from 'redux';
import {reducer as toastrReducer} from 'react-redux-toastr';
import { i18nReducer } from 'react-redux-i18n';
import auth from './auth';
import presentation from '../container/Presentation/reducer';
import { reducer as formReducer } from 'redux-form'

export default combineReducers({
  form: formReducer,
  toastr: toastrReducer,
  auth,
  i18n: i18nReducer,
  presentation,
});
