import React, { Component } from 'react';
import { connect } from 'react-redux'
import { likeItem } from '../../Presentation/actions';
import classNames from 'classnames';
import { isBlockedSpiner } from '../../../actions';

class Like extends Component {

  like = (value) => {
    const { session_id, slide_id, slide } = this.props;
    this.props.isBlockedSpiner(true);
    this.props.likeItem({
      value, 
      session_id,
      item_id: slide_id,
      student_id: localStorage.getItem('studentId'),
    })
  }

  render() {
    const {slide} = this.props;
    return (
      <React.Fragment>
        <div className={classNames("icon-item", {unlike: slide.like_value == -1})} onClick={() => {
           this.like(-1)
        }}>
           <i className="fas fa-thumbs-down"></i>
        </div>
        <div className={classNames("icon-item", {like: slide.like_value == 1})} onClick={() => {
          this.like(1)
        }}>
          <i className="fas fa-thumbs-up"></i>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  session_id: state.presentation.session_id,
  slide_id: state.presentation.slide_id,
})

const mapDispatchToProps = {
  likeItem,
  isBlockedSpiner,
}

export default connect(mapStateToProps, mapDispatchToProps)(Like)
