import RestClient from 'flex-rest-client';
import config from './index';
const rs = new RestClient();

function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
      return response
  } else {
      // let error = new Error(response ? (response.statusText || 'Error') : 'Error');
      // error.response = response;
      return Promise.resolve().then(() => {
          return response.json();
      }).then(res => {
          res.status = response.status;
          return Promise.reject(res)
      });
  }
}

function makeRequest({url, method, headers, bodyJSObject}) {
  const body = bodyJSObject ? JSON.stringify(bodyJSObject) : undefined;
  return fetch(url,
      {
          method: method || 'GET',
          headers,
          body,
      }).then(checkHttpStatus).then((response) => {
        return response.json();
      })
}

const options = {
  baseUrl: config.apiUrl + '/api',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  requestMethod: makeRequest,
};

// if (localStorage.getItem('token')) {
//   options.headers['Token'] = 'Bearer '+localStorage.getItem('token');
// }

rs.config(options);

export default rs;