import RestClient from './config/rest-client';
import { loadTranslations, setLocale } from 'react-redux-i18n';

export function me() {
  return async (dispatch) => {
    try {
      let translates = await RestClient.get('auth', 'translate');
      dispatch(loadTranslations(translates.result));
      dispatch(setLocale('en'));
      // let result = await RestClient.get('auth', 'me');
      dispatch({type: 'IS_LOADED', data: true});
    } catch (error) {
      dispatch({type: 'IS_LOADED', data: true});
    }
  }
}


export function isLoaded() {
  return async (dispatch) => {
    dispatch({type: 'IS_LOADED', data: true});
  }
}


export function isBlockedSpiner(data) {
  return async (dispatch) => {
    dispatch({type: 'IS_BLOCKED_SPINER', data});
  }
}

export function setShowModalInfo(data) {
  return async (dispatch) => {
    dispatch({type: 'SET_SHOW_MODAL_INFO', data});
  }
}

export function setPresentationMode(body) {
  return async (dispatch) => {
    try {
      await RestClient.post('presentations', 'mode', body);
    } catch (error) {
      console.log("🚀 ~ file: actions.js ~ line 43 ~ return ~ error", error)
      
    }
    // dispatch({type: 'SET_PRESENTATION_MODE', data});
  }
}

export function setPause(id, body) {
  return async (dispatch) => {
    try {
      dispatch({type: 'IS_BLOCKED_SPINER', data: true});
      const data = await RestClient.update('presentationprogress', id, body);
      // dispatch({type: 'SET_PRESENTATION', data})
    } catch (error) {
     
    }
  }
}