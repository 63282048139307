import React, { Component } from 'react';
import Slide from './components/Slide';
import Chat from './components/Chat';
import { connect } from 'react-redux'
import './style.scss';
import ActionsPresentation from './components/ActionsPresentation';
import StudentName from '../StudentName/StudentName';


class View extends Component {
  constructor(props) {
    super(props)
    this.state = {show_chat: true}
  }

  changeShowChat = (status) => {
    this.setState({
      show_chat: status,
    })
  }
  
  render() {
    const {start, is_view} = this.props;
    console.log("🚀 ~ file: index.jsx ~ line 24 ~ View ~ render ~ start", start)
    const {show_chat} = this.state;
    if(!start){
      return null
    }
    return (
      <div className="View" id="view-container"> 
        {/* <ActionsPresentation /> */}
        <Slide show_chat={show_chat} />
        {!is_view && <Chat show_chat={show_chat} changeShowChat={this.changeShowChat} />}
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  start: state.presentation.start,
  is_view: state.auth.is_view,
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
