const defaultState = {
  is_loaded: false,
  is_logined: false,
  profile: {},
  student_id: null,
  is_proector: false,
  is_view: false,
  is_blocked_spiner: false,
  show_info: false,
  modal_feetback: false,
  start_presentation: {
    show: false,
    start_date: '',
    name: '',
    start_message: ''
  }
}

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_PROFILE':
      return {
        ...state,
        profile: action.data,
        is_loaded: true,
        is_logined: true,
      };
    case 'IS_LOADED':
      return {
        ...state,
        is_loaded: action.data,
      };
    case 'LOGOUT':
      return {
        is_loaded: true,
        is_logined: false,
        profile: {}
      };
    case 'SET_AUTH_KEY': 
      return {
        ...state,
        [action.key]: action.data,
      }
    case 'IS_LOGINED':
      return {
        ...state,
        is_logined: action.data,
      };
    case 'SET_STUDENT_ID':
      return {
        ...state,
        student_id: action.data,
      };
    case 'IS_PROECTOR':
      return {
        ...state,
        is_proector: action.data,
      };
    case 'IS_VIEW':
      return {
        ...state,
        is_view: action.data,
      };
    case 'IS_BLOCKED_SPINER': 
      return {
        ...state,
        is_blocked_spiner: action.data,
      }  
    case 'SET_SHOW_MODAL_INFO': 
      return {
        ...state,
        show_info: action.data,
      }  
    case 'SET_PRESENTATION_MODE': 
      return {
        ...state,
        presentation_mode: action.data,
      }  
    case 'SET_MODAL_FEETBACK': 
      return {
        ...state,
        modal_feetback: action.data,
      }  
    default:
      return state
  }
}

export default auth;
