import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.scss';
import { I18n } from 'react-redux-i18n';
import Spiner from '../../../../components/Spiner';
import QRCode from 'qrcode.react';
import { startPresentation } from '../../actions';

class StartProector extends Component {
  constructor(props) {
    super(props)
    this.refHead = React.createRef();
    this.state = {
      qrH: 500
    }
  }
  
  viewCode = () => {
    const {presentation} = this.props;
    let tmp = presentation.code || '';
    let result = '';

    while (tmp.length/3 >= 2) {
      result += tmp.slice(0, 3) + '&nbsp&nbsp';
      tmp = tmp.slice(3, tmp.length)
    }
    return result + tmp;
  }

  componentDidMount(){
    if(this.refHead.current){
      this.setState({
        qrH: window.innerHeight - this.refHead.current.clientHeight - 100,
      })
    }
    window.addEventListener('resize', this.updateSize);
  }

  updateSize = () => {
    if(this.refHead.current){
      this.setState({
        qrH: window.innerHeight - this.refHead.current.clientHeight - 100,
      })
    }
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.updateSize)
  }

  render() {
    const {start, presentation, count_student, token_student, password} = this.props;
    if(start){
      return null;
    }

    const {qrH} = this.state;

    
    console.log("StartProector -> render -> document.height", window.innerHeight)

    return (
      <div className="StartPage">
        <div ref={this.refHead} style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <h2>{presentation.name}</h2>
          <h4>{window.location.origin}</h4>
          <p>{I18n.t('enterCode')}</p>
          <div className="code" dangerouslySetInnerHTML={{__html: this.viewCode()}}></div>
          <p>{I18n.t('presentationPassword')}</p>
          <div className="code">{password}</div>
          <p>{I18n.t('scanQrCode')}</p>
        </div>
        <QRCode value={ window.location.origin + '/presentation/' + token_student} size={qrH} />
        <div className="active-student">
          <div>{count_student} students joined this session</div>
        </div>

      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  start: state.presentation.start,
  presentation: state.presentation.presentation,
  count_student: state.presentation.count_student,
  session_id: state.presentation.session_id,
  token_student: state.presentation.token_student,
  password: state.presentation.password || '1234',
})

const mapDispatchToProps = {
  startPresentation
}

export default connect(mapStateToProps, mapDispatchToProps)(StartProector)

