import React, { Component } from 'react';
import { ChromePicker  } from 'react-color';
import './ColorInput.scss';

class ColorInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
    this._Ref = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }
  handleClick = (event) => {
    if (this._Ref && this._Ref.current.contains(event.target)) {
      return;
    }
    this.setState({ active: false });
  };

  togglePopover = () => {
    this.setState(({ active }) => {
      return { active: !active };
    });
  };

  render() {
    const {active} = this.state;
    const {color, onChangeColor} = this.props;
    return <div className='ColorInput' ref={this._Ref}>
      <div className="btn-color" style={{backgroundColor: color}} onClick={this.togglePopover}>

      </div>
      {active && <div className="draw-content">
        <ChromePicker
          color={ color }
          onChangeComplete={onChangeColor}
        />
      </div>}
    </div>
    // ;
  }
}

export default ColorInput;
