import React, { Component } from 'react';
import classNames from 'classnames';
// import './FieldInput.scss';

class FieldTextarea extends Component {
  render() {
    const {input, label, type, placeholder, className, meta: {error, touched}} = this.props;
    return (
      <div className={classNames("form-group", 'FieldTextarea', {'error': touched && error}, className)}>
        {label && <label>{label}</label>}
        <textarea 
          {...input}
          type={type}
          className={classNames("form-control", {'is-invalid': touched && error})}
          placeholder={placeholder}
        />
        {touched && ((error && <div className="invalid-feedback">{error}</div>))}
         
      </div>
    );
  }
}


export default FieldTextarea;
