var entities = {
  'amp': '&',
  'apos': '\'',
  'lt': '<',
  'gt': '>',
  'quot': '"',
  'nbsp': '\xa0',
};
var entityPattern = /&([a-z]+);/ig;

const decodeHTMLEntities = (text) => {
  return text.replace(entityPattern, function(match, entity) {
    entity = entity.toLowerCase();
    if (entities.hasOwnProperty(entity)) {
      return entities[entity];
    }
    // return original string if there is no matching entity (no replace)
    return match;
  }).replace(/&#x27;/g, "'");
}

export default decodeHTMLEntities