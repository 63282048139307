import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { I18n } from 'react-redux-i18n';
import { Field, reduxForm } from 'redux-form'
import FieldStars from '../../components/Fields/FieldStars';
import FieldTextarea from '../../components/Fields/FieldTextarea';
import { required, length } from 'redux-form-validators';
import { connect } from 'react-redux'
import { changeModalFeetback, sendFeetback } from './actions';
import { closePresentation } from '../Presentation/actions';
import Spiner from '../../components/Spiner';

class Feetback extends Component {

  state = {
    spinner: false
  }

  toggle = () => {
    const { session_id} = this.props;
    this.props.changeModalFeetback(false);
    this.props.closePresentation(session_id)
  }

  send = async (values) => {
  const {profile, sendFeetback, session_id} = this.props;

  if(!values.stars && !values.content){
    this.toggle()
    return;
  }

   this.setState({spinner: true})
    await sendFeetback({
      ...values,
      user_id: profile.id
    });
    this.setState({spinner: false})
    this.toggle();
  }

  render() {
    const { handleSubmit, modal_feetback} = this.props
    const {spinner} = this.state;
    return (
      <Modal isOpen={modal_feetback} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>{I18n.t('titleFeetback')}</ModalHeader>
        <ModalBody>
          {spinner && <div style={{width: '100%', height: 235}}>
            <Spiner />
          </div>}
          {!spinner && <form onSubmit={handleSubmit(this.send)}>
            <Field label={I18n.t('ttStars')} component={FieldStars} name='stars' validate={[required()]}/>
            <Field label={I18n.t('ttSContent')} component={FieldTextarea} name='content' validate={[length({ max: 500 })]} />
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <button className="btn btn-primary" type="submit">
                {I18n.t('btnSendFeetback')}
              </button>
              <button className="btn btn-secondary" type="button" onClick={this.toggle}>
                {I18n.t('btnSendFeetbackSkip')}
              </button>
            </div>
            
          </form>}
        </ModalBody>
      </Modal>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  profile: state.auth.profile,
  modal_feetback: state.auth.modal_feetback,
  session_id: state.presentation.session_id,
})

const mapDispatchToProps = {
  changeModalFeetback,
  sendFeetback,
  closePresentation,
}

Feetback = reduxForm({
  form: 'feetback' // a unique identifier for this form
})(Feetback)

export default connect(mapStateToProps, mapDispatchToProps)(Feetback)
