import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux'
import QRCode from 'qrcode.react';
import { I18n } from 'react-redux-i18n';
import './QRCode.scss'
import {toastr} from 'react-redux-toastr'
import { setShowModalInfo } from '../../../actions';
import SocketContext from '../../../SocketContext';

class QRCodeModal extends Component {
  toggle = () => {
    const {io} = this.context;
    const {presentation, is_logined, show_info} = this.props;

    this.props.setShowModalInfo(!show_info);
    if(is_logined){
      io.socket.request({
        method: 'post',
        url: '/api/auth/socket_message',
        data: {
          presentation_id: presentation.id, 
          type: 'show_info_proector',
          data: !show_info
        }
      });
    }
    
  }

  render() {
    const {hideEl} = this.props;

    if(hideEl){
      return null;
    }

    return (
      <React.Fragment>
        <div className="icon-item" onClick={() => {
          this.toggle();
          
        }}>
          <i className="fas fa-info"></i>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  show_info: state.auth.show_info,
  presentation: state.presentation.presentation,
  is_logined: state.auth.is_logined,
})

const mapDispatchToProps = {
  setShowModalInfo
}

QRCodeModal.contextType = SocketContext;

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(QRCodeModal)

