import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux'
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { setPause } from '../../actions';

class PauseScreen extends Component {

  constructor(props) {
    super(props)
    this.time = null;
    this.state = {
      second: 0,
    }
  }
  

  componentDidMount = () => {
    

    const {pause_date, pause_time} = this.props;
    
    if(moment().unix() - pause_date < pause_time) {
      this.setState({second:  pause_time - (moment().unix() - pause_date)},() => {
        let sound = document.getElementById('bg-sound');
        if(sound){
          sound.play();
        }
      })
      this.startTimer()
    }

  };

  componentWillReceiveProps(nextProps){
    const {pause_date, pause_time} = nextProps;
    console.log("🚀 ~ file: PauseScreen.jsx ~ line 38 ~ PauseScreen ~ componentWillReceiveProps ~ pause_date, pause_time", pause_date, pause_time)
    if(moment().unix() - pause_date < pause_time){
      this.setState({second:  pause_time - (moment().unix() - pause_date)},() => {
        let sound = document.getElementById('bg-sound');
        if(sound){
          sound.play();
        }
      })
      this.startTimer();
    } else {
      clearInterval(this.time);
      this.setState({second: 0})
    }
  }

  getTime = (seconds) => {
    var sec_num = parseInt(seconds, 10);
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
  }

  startTimer = () => {

    clearInterval(this.time);
    this.time = setInterval(() => {
      const {pause_time, pause_date} = this.props
      let second = pause_time - (moment().unix() - pause_date);
      
      if(second <= 0){
        clearInterval(this.time);
        this.setState({second: 0})
      } else {
        this.setState({second})
      }
      
    }, 1000);
  }
  
  render() {
    const {second} = this.state;
    const {is_logined, session_id} = this.props;

    if(second <= 0){
      return null;
    }

    return (
      <div className="PauseScreen">
        {/* <audio id="bg-sound" loop>
          <source src="/bg.mp3"/>
        </audio> */}
        <div className="wrap-container">
          <h1>{I18n.t('titleMessagePauseBlock')}</h1>
          <div className="time">{this.getTime(second)}</div>
          {is_logined && <button onClick={() => {
            this.props.setPause(session_id, {
              pause_date: 0,
              pause_time: 0,
            })
          }} className="btn btn-danger">{I18n.t('cancelPauseBlock')}</button>}
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  is_logined: state.auth.is_logined,
  pause_date: state.presentation.pause_date,
  pause_time: state.presentation.pause_time,
  session_id: state.presentation.session_id,

})

const mapDispatchToProps = {
  setPause
}

export default connect(mapStateToProps, mapDispatchToProps)(PauseScreen)
