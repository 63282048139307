import React, { Component } from 'react';
import './style.scss';
import Image from './components/Image';
import SlidItems from './components/SlidItems';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Question from './components/Question';
import { Swipeable } from 'react-swipeable';
import {orderBy} from 'lodash';
import { setSlide, setSketch } from '../../../../actions';
import SocketContext from '../../../../../../SocketContext';

class Slide extends Component {

  constructor(props) {
    super(props)
    this.state = {
      heightSlideItems: 0,
      isDrawPaint: false,
    }
  }

  setHeightSlideItems = (heightSlideItems) => {
    this.setState({heightSlideItems})
  }

  setIsDrawPaint = (status) => {
    this.setState({isDrawPaint: status})
  }
  

  componentWillUnmount(){
    document.getElementById('layout').style.background = 'white';
  }
  render() {
    const {items, slide_id, session_id, is_logined, presentation} = this.props;
    const {heightSlideItems} = this.state;

    let slide = null;

    let prevId = null;
    let nextId = null;

    let tmp_items = items.map(item => ({
      ...item, 
      order_x: isNaN(parseInt(item.order_x)) ? 0 : parseInt(item.order_x)
    }));

    tmp_items = orderBy(items, ['order_x'], ['asc']);

    

    tmp_items.some((item, index) => {
      if(item.id == slide_id) {
        slide = item;
        if(index > 0 && tmp_items[index-1].id){
          prevId = tmp_items[index-1].id;
        }

        if(index + 1 <= tmp_items.length - 1){
          nextId = tmp_items[index+1].id;
        }
        return true;
      }
    });

    if(!slide){
      return null;
    }

    if(slide.type == 'image'){
      document.getElementById('layout').style.background = '#e5e5e5';
    } else {
      document.getElementById('layout').style.background = 'white';
    }

    const config = {
      delta: 30,                             // min distance(px) before a swipe starts
      preventDefaultTouchmoveEvent: true,   // preventDefault on touchmove, *See Details*
      trackTouch: true,                      // track touch input
      trackMouse: false,                     // track mouse input
      rotationAngle: 20,                      // set a rotation angle
    }

    return (
      // <Swipeable 
      //   className={classNames("Slide", 'col-md-12')}
      //   onSwipedLeft={() => {

      //     const {isDrawPaint} = this.state;

      //     if(nextId && is_logined && !isDrawPaint){
      //       this.props.setSlide(session_id, nextId);
      //     }
      //   }}
      //   onSwipedRight={() => {
      //     const {isDrawPaint} = this.state;

      //     if(prevId && is_logined && !isDrawPaint){
      //       this.props.setSlide(session_id, prevId);
      //     }
      //   }}
      //   {...config}
      // >
      <div className={classNames("Slide", 'col-md-12')}>
        {slide.type =='image' && <Image 
          setIsDrawPaint={this.setIsDrawPaint} 
          setSketch={this.props.setSketch} 
          heightSlideItems={heightSlideItems} 
          slide_id={slide_id} 
          slide={slide} 
          presentation={presentation} 
          is_logined={is_logined} 
        />}
        {slide.type =='question' && <Question slide={slide} />}
        <SlidItems setHeightSlideItems={this.setHeightSlideItems} />
      </div>
      // </Swipeable>
    );
  }
}



const mapStateToProps = (state, ownProps) => ({
  items: state.presentation.items,
  slide_id: state.presentation.slide_id,
  session_id: state.presentation.session_id,
  presentation: state.presentation.presentation,
  is_logined: state.auth.is_logined,
})

const mapDispatchToProps = {
  setSlide,
  setSketch,
}

export default connect(mapStateToProps, mapDispatchToProps)(Slide)
