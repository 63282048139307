import React, { useEffect, useRef, useState } from 'react'
import TextSelector from 'text-selection-react'
import Popover from "react-text-selection-popover";
import { useTextSelection } from 'use-text-selection'
import './style.scss'
import { useDispatch, useSelector } from 'react-redux';
import { sendQuestion } from '../../../../../../../../../../actions';

function getSelectedText() {
  const t = (document.all) ? document.selection.createRange().text : document.getSelection();
  return t;
}

const defaultBarState = {
  display: 'none',
  top: 0,
  left: 0,
  id: ''
}

function Question({question, slide_id}) {

  const dispatch = useDispatch();

  const presentation = useSelector(state => state.presentation.presentation)
  const isLogined = useSelector(state => state.auth.is_logined)

  const refQuestion = useRef();
  const ref = useRef();

  const [bar, setBar] = useState(defaultBarState)

  
  const onClickSelected = (el) => (e) => {
  console.log("🚀 ~ file: Question.jsx ~ line 44 ~ onClickSelected ~ el", el)

    var rect = el.getBoundingClientRect();
    const container = refQuestion.current.getBoundingClientRect();
    setBar({
      display: 'flex',
      top: rect.top - container.top + rect.height + 5,
      left: rect.left - container.left,
      id: el.id
    })
  }

  useEffect(() => {

    if(isLogined){
      var elements = document.getElementsByClassName("popover_selected-words");

      var myFunction = function() {
        onClickSelected(this)()
      };
  
      for (var i = 0; i < elements.length; i++) {
          elements[i].addEventListener('click', myFunction, false);
      }
    }
    
  })


  const handleEvent = () => {
    if(!isLogined){
      return;
    }
    var selection = getSelectedText();
    var selection_text = selection.toString();
    console.log("🚀 ~ file: Question.jsx ~ line 14 ~ handleEvent ~ selection_text", selection_text)
    if(selection_text){

      var data=selection_text.replace(/\r?\n|\r/g, "\n").split("\n").filter(Boolean)
      var dataWithSpan=data.map((x,y)=>`<span>${x}</span>`);
      console.log("🚀 ~ file: Question.jsx ~ line 75 ~ handleEvent ~ dataWithSpan", dataWithSpan)

      var range = selection.getRangeAt(0);
      // range.deleteContents();





      // var fragment = range.extractContents();
      var frangment = range.cloneContents();

      
      var newDiv = document.createElement("span");
      
      newDiv.style.background = '#fadb14'
      newDiv.style.cursor = 'pointer'
      newDiv.classList.add("popover_selected-words")
      newDiv.id = Date.now();
      newDiv.onclick = onClickSelected(newDiv)

      try {
        range.surroundContents(newDiv)
      } catch (error) {
        var ansector = range.commonAncestorContainer;
        var tagCollection = [];

        if (ansector.nodeType != 3) {
          tagCollection = Array.from(ansector.querySelectorAll('*'));
        }
        console.log("🚀 ~ file: Question.jsx ~ line 107 ~ handleEvent ~ tagCollection", tagCollection)

        if (frangment && frangment.childNodes && frangment.childNodes.length) {
          frangment.childNodes.forEach(function (child, i) {
            tagCollection.forEach(function (tag) {
              if (child.nodeType === 3 && child.nodeName == '#text' && tag.innerHTML.includes(child.textContent)) {
                var spanWrapper = document.createElement('span');
                spanWrapper.style.background = '#fadb14'
                spanWrapper.style.cursor = 'pointer'
                spanWrapper.classList.add("popover_selected-words")
                spanWrapper.id = Date.now();
                spanWrapper.onclick = onClickSelected(spanWrapper)
                spanWrapper.append(child.textContent);
                

                var Html = tag.innerHTML.replace(child.textContent, spanWrapper.innerHTML);
                tag.innerHTML = Html;
              } else if (tag.innerHTML && tag.innerHTML.includes(child.innerHTML)) {
                var _spanWrapper = document.createElement('span');

                _spanWrapper.style.background = '#fadb14'
                _spanWrapper.style.cursor = 'pointer'
                _spanWrapper.classList.add("popover_selected-words")
                _spanWrapper.id = Date.now();
                _spanWrapper.onclick = onClickSelected(spanWrapper)

                _spanWrapper.append(child.textContent);

                var _Html = tag.innerHTML.replace(child.innerHTML, _spanWrapper.outerHTML);

                tag.innerHTML = _Html;
              }
            });
          });
        }

      }


      // newDiv.appendChild(fragment);
      // range.insertNode(newDiv);
  
      dispatch({type: 'SET_UPDATE_QUESTION_ITEM', slide_id, data: ref.current.innerHTML})

      dispatch(sendQuestion({presentation_id: presentation.id, slide_id, content: ref.current.innerHTML}))
      onClickSelected(newDiv)()
    }
  }

  const clickColor = (color) => {
    const el = document.getElementById(bar.id);
    el.style.background = color;
    setBar(defaultBarState)
    dispatch({type: 'SET_UPDATE_QUESTION_ITEM', slide_id, data: ref.current.innerHTML})

    dispatch(sendQuestion({presentation_id: presentation.id, slide_id, content: ref.current.innerHTML}))

  }

  const deleteElement = () => {
    const el = document.getElementById(bar.id);
    el.style.background = null;
    el.style.cursor = null;
    el.id = null;
    el.onclick = () => {}
    el.classList.add("popover_selected-words")
    setBar(defaultBarState)
    dispatch({type: 'SET_UPDATE_QUESTION_ITEM', slide_id, data: ref.current.innerHTML})
    dispatch(sendQuestion({presentation_id: presentation.id, slide_id, content: ref.current.innerHTML}))
  }

  return (
    <>
      <div  className="question-content" ref={refQuestion}>
        <div className="bar-selected" style={bar}>
          <div className="item-color yellow" onClick={() => clickColor('#fadb14')}></div>
          <div className="item-color red" onClick={() => clickColor('#ffa39e')}></div>
          <div className="item-color green" onClick={() => clickColor('#73d13d')}></div>
         
          <div className="item-color delete" onClick={() => deleteElement()}>
            <i className="fas fa-trash"></i>
          </div> 
          <div className="item-color delete" onClick={() => setBar(defaultBarState)}>
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div ref={ref} onMouseUp={handleEvent}  dangerouslySetInnerHTML={{__html: question }}></div>
      </div>
    </>
  )
}

export default Question
