import React, { Component } from 'react';
import io from 'socket.io-client'
import RTCMultiConnection, {RecordRTC, DetectRTC} from 'rtcmulticonnection';
// import RTCMultiConnection from '../../../../lib/RTCMultiConnection'
import config from '../../../../config';
import './style.scss';
import { getHTMLMediaElement } from './getHTMLMediaElement';
import { connect } from 'react-redux'
import SocketContext from '../../../../SocketContext';
import Draggable from 'react-draggable';
import { Resizable, ResizableBox } from 'react-resizable';
import classNames from 'classnames';

class SoundVideo extends Component {
  constructor(props) {
    super(props)
    window.io = io;
    this.localVideo = React.createRef()
    const connection = new RTCMultiConnection();
    this.state = {
      start: false,
      show: false,
      localStream: {},
      roomId: '',
      streamId: '',
      muteVideo: [],
      muteAudio: [],
    }

    this.isTeacher = ~window.location.pathname.indexOf('/presentation/teacher/');

    connection.socketURL = 'https://rtcmulticonnection.herokuapp.com:443/';

    connection.session = {
      audio: true,
      video: { width: 320, height: 240  }
    };

    
    // connection.enableScalableBroadcast = true;
    connection.maxRelayLimitPerUser = 1;
    // connection.autoCloseEntireSession = true;
    connection.sdpConstraints.mandatory = {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true
    };

    connection.iceServers = [{
      'urls': [
          'stun:stun.l.google.com:19302',
          'stun:stun1.l.google.com:19302',
          'stun:stun2.l.google.com:19302',
          'stun:stun.l.google.com:19302?transport=udp',
        ]
      },
      {
        urls: 'turn:numb.viagenie.ca',
        username: 'listat.an@gmail.com',
        credential: 'Listat13'
      },
    ];

    const CodecsHandler = connection.CodecsHandler;

    connection.processSdp = function(sdp) {
      var codecs = 'vp8';
      sdp = CodecsHandler.preferCodec(sdp, codecs.toLowerCase());
      return sdp;
    };
    this.connection = connection;
  }


  muteAudio(streamId){
    const {presentation} = this.props;

    if(this.state.streamId == streamId) {
      io.socket.request({
        method: 'post',
        url: '/api/auth/socket_message',
        data: {
          presentation_id: presentation.id, 
          type: 'video_sound_mute',
          data: {
            streamId
          },
        }
      });
    }
    
  }


  muteVideo(streamId){
    const {presentation} = this.props;

    if(this.state.streamId == streamId) {
      io.socket.request({
        method: 'post',
        url: '/api/auth/socket_message',
        data: {
          presentation_id: presentation.id, 
          type: 'video_video_mute',
          data: {
            streamId
          },
        }
      });
    }
    
  }

  componentDidMount(){
    const connection = this.connection;
    const {io} = this.context;

    window.addEventListener('unload', (event) => {
      if(navigator && navigator.sendBeacon){
        navigator.sendBeacon(config.apiUrl + '/api/auth/socket_message', JSON.stringify({
          presentation_id: this.props.presentation.id, 
          type: 'remove_video_container',
          data: {
            streamId: this.state.streamId
          },
        }));  
      }

      if(this.props.is_logined){
        if(navigator && navigator.sendBeacon){
          navigator.sendBeacon(config.apiUrl + '/api/auth/socket_message', JSON.stringify({
            presentation_id: this.props.presentation.id, 
            type: 'stop_video_record',
            data: {},
          }));  
        }
      }

    });

    connection.videosContainer = document.getElementById('videos-container');
    const videosContainer = document.getElementById('videos-container');
    connection.onstream = (event) => {
      try {
        this.setState({start: true});
        const existing = document.getElementById('video_' + event.streamid);     

        if(existing && existing.parentNode && existing.parentNode.parentNode) {
          existing.parentNode.parentNode.removeChild(existing.parentNode);
        }

        event.mediaElement.removeAttribute('src');
        event.mediaElement.removeAttribute('srcObject');
        event.mediaElement.muted = true;
        event.mediaElement.volume = 0;

        const video = document.createElement('video');

        try {
          video.setAttributeNode(document.createAttribute('autoplay'));
          video.setAttributeNode(document.createAttribute('playsinline'));
        } catch (e) {
            video.setAttribute('autoplay', true);
            video.setAttribute('playsinline', true);
        }

        if(event.type === 'local') {
          this.state.streamId = event.streamid;
          video.volume = 0;
          try {
              video.setAttributeNode(document.createAttribute('muted'));
          } catch (e) {
              video.setAttribute('muted', true);
          }
        }
        
        video.srcObject = event.stream;

        video.id = 'video_' + event.streamid;

        video.style.width = '320px';
        video.style.height = '240px';

        const container = document.createElement('div');
        container.classList.add('video-container');
        container.id = 'video_container_' + event.streamid;
        container.appendChild(video);

        const actions_view = document.createElement('div');
        actions_view.classList.add('actions-view');

        actions_view.id = 'actions_view_' + event.streamid;
        container.appendChild(actions_view);

        if(event.type === 'local') {
         
          
          const actions = document.createElement('div');
          actions.classList.add('actions');
  
          const btnSound = document.createElement('div');
          btnSound.classList.add('btn-icon');
          btnSound.innerHTML = '<i class="fas fa-microphone"></i>'
          btnSound.addEventListener('click', this.muteAudio.bind(this, event.streamid));
          btnSound.id = 'sound_act_' + event.streamid;
          actions.appendChild(btnSound);
          
          const btnVideo = document.createElement('div');
          btnVideo.classList.add('btn-icon');
          btnVideo.innerHTML = '<i class="fas fa-video"></i>'
          btnVideo.addEventListener('click', this.muteVideo.bind(this, event.streamid));
          btnVideo.id = 'video_act_' + event.streamid;
          actions.appendChild(btnVideo);


          container.appendChild(actions);
          this.showPanel(true)
        }

        
        videosContainer.appendChild(container);

       
      } catch (error) {
        console.log("SoundVideo -> connection.onstream -> error", error)
        
      }
  
      if(event.type === 'local') {
        this.connection.socket.on('disconnect', () => {
          
        });
      }
    };
    
    connection.onstreamended = (event) => {
      console.log("SoundVideo -> connection.onstreamended -> event")
      
    };
    
    connection.onMediaError = (e) => {
        if (e.message === 'Concurrent mic process limit.') {
           console.log('Concurrent mic process limit.')
        }
    };

    io.socket.on('record_video_sound', (data) => {
      if(!this.isTeacher && !this.state.start){
        this.joinRoom(data.roomId);
      }
      // 
    });


    io.socket.on('stop_video_record', (data) => {
      this.stopRecording();
      this.setState({
        start: false,
        roomId: '',
      })
    });
    
    io.socket.on('join_to_room_video', (data) => {
      const { presentation } = this.props;

      if(this.state.start && this.state.roomId){
        io.socket.request({
          method: 'post',
          url: '/api/auth/record_video_sound?presentation_id=' + presentation.id ,
          data: {id: presentation.id, roomId: this.state.roomId}
        }, (body, response) => {
      
        });
      } 
    });

    io.socket.on('remove_video_container', (data) => {
      console.log("SoundVideo -> componentDidMount -> data", data)
      if(data && data.streamId){  
        const existing = document.getElementById('video_' + data.streamId);     

        if(existing && existing.parentNode && existing.parentNode.parentNode) {
          existing.parentNode.parentNode.removeChild(existing.parentNode);
        }
      }
    })

    io.socket.on('video_video_mute', (data) => {
      const {muteVideo, muteAudio} = this.state;
      if(data.streamId){
        const video = document.getElementById('video_' + data.streamId);
        if(video){

          if(muteVideo.indexOf(data.streamId) > -1){
            video.play()


            if(data.streamId == this.state.streamId){
              const video_act = document.getElementById('video_act_' + data.streamId);
              video_act.innerHTML = '<i class="fas fa-video"></i>'
            } else {
              const container_actions_view = document.getElementById('actions_view_' + data.streamId);
              container_actions_view.classList.remove('bg')
              if(muteAudio.indexOf(data.streamId) > -1){
                container_actions_view.innerHTML = '<i class="fas fa-microphone-slash"></i>';
              } else {
                container_actions_view.innerHTML = '';
              }
            }
            this.state.muteVideo = muteVideo.filter(id => id != data.streamId);
          } else {
            video.pause();
  
            if(data.streamId == this.state.streamId){
              const video_act = document.getElementById('video_act_' + data.streamId);
              video_act.innerHTML = '<i class="fas fa-video-slash"></i>'
            } else {
              const container_actions_view = document.getElementById('actions_view_' + data.streamId);
              container_actions_view.classList.add('bg')
              if(muteAudio.indexOf(data.streamId) > -1){
                container_actions_view.innerHTML = '<i class="fas fa-microphone-slash"></i><i class="fas fa-video-slash"></i>';
              } else {
                container_actions_view.innerHTML = '<i class="fas fa-video-slash"></i>';
              }
            }

            this.state.muteVideo = [...muteVideo, data.streamId]
          }
        }
      }
    });

    io.socket.on('video_sound_mute', (data) => {
      const {muteAudio, muteVideo, streamId} = this.state;
      if(data.streamId){
        const video = document.getElementById('video_' + data.streamId);
        if(video){

          if(muteAudio.indexOf(data.streamId) > -1){

            if(data.streamId != streamId){
              video.volume = 1;
              try {
                  video.removeAttributeNode(document.createAttribute('muted'));
              } catch (e) {
                  video.setAttribute('muted', false);
              }
            }

            


            if(data.streamId == this.state.streamId){
              const sound_act = document.getElementById('sound_act_' + data.streamId);
              sound_act.innerHTML = '<i class="fas fa-microphone"></i>'
            } else {
              const container_actions_view = document.getElementById('actions_view_' + data.streamId);
              container_actions_view.innerHTML = '';
              if(muteVideo.indexOf(data.streamId) > -1){
                container_actions_view.innerHTML = '<i class="fas fa-video-slash"></i>';
              } else {
                container_actions_view.innerHTML = '';
              }
            }
            this.state.muteAudio = muteAudio.filter(id => id != data.streamId);
          } else {
            if(data.streamId != streamId){
              video.volume = 0;
              try {
                  video.setAttributeNode(document.createAttribute('muted'));
              } catch (e) {
                  video.setAttribute('muted', true);
              }
            }
  
            if(data.streamId == this.state.streamId){
              const sound_act = document.getElementById('sound_act_' + data.streamId);
              sound_act.innerHTML = '<i class="fas fa-microphone-slash"></i>'
            } else {
              const container_actions_view = document.getElementById('actions_view_' + data.streamId);
              if(muteVideo.indexOf(data.streamId) > -1){
                container_actions_view.innerHTML = '<i class="fas fa-video-slash"></i><i class="fas fa-microphone-slash"></i>';
              } else {
                container_actions_view.innerHTML = '<i class="fas fa-microphone-slash"></i>';
              }
            }

            this.state.muteAudio = [...muteAudio, data.streamId]
          }
        }
      }
    });

  }
  // record_video_sound
  openRoom = () => {

    const { presentation } = this.props;

    const roomId = Date.now();

    this.connection.open(roomId, (isRoomOpened, roomid, error) => {
      if (isRoomOpened === true) {
        this.setState({
          start: true,
          roomId,
        });
        const {io} = this.context;
        

        io.socket.request({
          method: 'post',
          url: '/api/auth/record_video_sound?presentation_id=' + presentation.id ,
          data: {id: presentation.id, roomId}
        }, (body, response) => {
      
        });

        // io.Socket.emit('start_video_room', {id: presentation.id});
      } else {
          
          if (error === this.connection.errors.ROOM_NOT_AVAILABLE) {
              alert('Someone already created this room. Please either join or create a separate room.');
              return;
          }
          alert(error);
      }
    });
  }

  joinRoom = (roomId) => {
    const { presentation } = this.props;
    this.connection.join(roomId, (isJoinedRoom, roomid, error) => {
      if (error) {
        
          if (error === this.connection.errors.ROOM_NOT_AVAILABLE) {
              alert('This room does not exist.');
              return;
          }
          if (error === this.connection.errors.ROOM_FULL) {
              alert('Room is full.');
              return;
          }
          alert(error);
      }
    });
  }


  stopRecording = () => {
    var recorder = this.connection.recorder;

    if(recorder){
      recorder.stopRecording(function() {
        this.connection.recorder = null;
      });
    }
    this.connection.getAllParticipants().forEach((pid) => {
      this.connection.disconnectWith(pid);
    });

    // stop all local cameras
    this.connection.attachStreams.forEach(function(localStream) {
        localStream.stop();
    });

    // close socket.io connection
    this.connection.closeSocket();
    this.setState({
      start: false,
      roomId: null
    });

    document.getElementById('videos-container').innerHTML = "";
    this.showPanel(false)
    const elViewContainer = document.getElementById('view-container');
    if(elViewContainer){
      elViewContainer.style.marginRight = '0px';
    }
  }

  showPanel = (status) => {
    const elViewContainer = document.getElementById('view-container');
    const el = document.getElementById('SlidItems');
    if(elViewContainer){
      elViewContainer.style.marginRight = '19px';
      if(status){
        elViewContainer.style.width = 'calc(100% - 364px)';
        if(window.setSizeImage && el){
          window.setSizeImage(el.clientHeight + 20);
        }

        const actionsItemsEl = document.getElementById('ActionsItems');

        if(actionsItemsEl){
          actionsItemsEl.style.paddingRight = '350px';
          if(window.setMinimiseActions){
            window.setMinimiseActions(true)
          }
        }

      } else {
        if(window.setSizeImage && el){
          window.setSizeImage(el.clientHeight + 20);
        }
        elViewContainer.style.width = '100%';
        const actionsItemsEl = document.getElementById('ActionsItems');

        if(actionsItemsEl){
          actionsItemsEl.style.paddingRight = '0px';
          if(window.setMinimiseActions){
            window.setMinimiseActions(false)
          }
        }
      }
    }
    this.setState({show: status})
  }

  closeRoom = () => {
    io.socket.request({
      method: 'post',
      url: '/api/auth/socket_message',
      data: {
        presentation_id: this.props.presentation.id, 
        type: 'stop_video_record',
        data: {},
      }
    });
  }

  startVideo = () => {
    const {start} = this.state;

    if(!this.isTeacher){
      this.joinRoom();
      return;
    }

    if(start){
      this.closeRoom();
    } else {
      this.openRoom();
    }
  }
  
  render() {
    const {start, show} = this.state;
    const { presentation } = this.props;
    return (
      <React.Fragment>
        <div className={classNames("block-conferenc", {show})} style={{display: start ? 'flex' : 'none' }} >
          <div className="full-conferenc" id="videos-container">
            {/* <div className="video-container">
              <video style={{width: 320, height: 240}} />
              <div className="actions-view">
                <i className="fas fa-volume-mute"></i>
              </div>
              <div className="actions">
                <div className="btn-icon"><i className="fas fa-video"></i></div>
                <div className="btn-icon"><i className="fas fa-volume-up"></i></div>
              </div>
            </div> */}
          </div>
          <div className="action-show" onClick={() => this.showPanel(!show)}>
            {!show ? <i className="fas fa-chevron-left"></i> : <i className="fas fa-chevron-right"></i>}
          </div>
        </div>
        
      </React.Fragment>
      
    );
  }
}

SoundVideo.contextType = SocketContext;


const mapStateToProps = (state, ownProps) => ({
  presentation: state.presentation.presentation,
  is_logined: state.auth.is_logined,
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(SoundVideo)
