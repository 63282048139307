import React, { Component } from 'react';
import './style.scss'
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux'
import { closePresentation } from '../../../../actions';


class ActionsPresentation extends Component {
  render() {
    const {session_id, is_logined} = this.props;
    return (
      <div className="ActionsPresentation">
        {is_logined && <div className="close-presentation" onClick={() => {this.props.closePresentation(session_id)}}>{I18n.t('titleBtnClosePr')}</div>}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  session_id: state.presentation.session_id,
  is_logined: state.auth.is_logined,
})

const mapDispatchToProps = {
  closePresentation
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsPresentation)

