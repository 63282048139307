import RestClient from '../../config/rest-client';
import history from '../../lib/history';
import {toastr} from 'react-redux-toastr'
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

export async function sendLogin(body) {
    try {
      const data = await RestClient.post('auth', 'login', body);
      return data;
    } catch (error) {
      toastr.error('Error', I18n.t('notFoundPresentation'))
      return false
    }
  
}