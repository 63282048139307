import {cloneDeep} from 'lodash';

const defaultState = {
  start: false,
  presentation: {},
  items: [],
  count_student: 0,
  slide_id: null,
  session_id: null,
  messages: [],
  pause_date: 0,
  pause_time: 0,
  presentation_mode: '',
  token_student: '',
  token_proector: '',
}

const presentation = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_PRESENTATION':
      return {
        ...state, 
        presentation: action.data.presentation,
        items: action.data.items,
        start: action.data.status == 1,
        slide_id: action.data.slide_id,
        session_id: action.data.session_id,
        messages: action.data.messages,
        pause_date: action.data.pause_date,
        pause_time: action.data.pause_time,
        presentation_mode: action.data.presentation_mode,
        token_student: action.data.token_student,
        token_proector: action.data.token_proector,
        password: action.data.password,
      };
    case 'SET_PRESENTATION_COUNT_STUDENT':
      return {
        ...state, 
        count_student: action.data,
      };
    case 'SET_PRESENTATION_MODE':
      return {
        ...state, 
        presentation_mode: action.data,
      };
    case 'SET_PRESENTATION_ITEM_LIKE':
      return {
        ...state, 
        items: cloneDeep(state.items).map(item => {
          if(item.id == action.data.item_id){
            return {...item, like_value: action.data.value}
          }
          return {...item};
        })
      };
    case 'PRESENTATION_ITEM_LIKE':
      return {
        ...state, 
        items: cloneDeep(state.items).map(item => {
          if(item.id == action.data.item_id){
            return {...item, 
              like_count: action.data.like_count,
              unlike_count: action.data.unlike_count,
            }
          }
          return {...item};
        })
      };
    case 'PRESENTATION_STUDENT_ANSWER':
      return {
        ...state, 
        items: cloneDeep(state.items).map(item => {
          if(item.src == action.data.question_id){
            return {...item, question_aswers_all: [...item.question_aswers_all, action.data]}
          }
          return {...item};
        })
      };
    case 'SHOW_PRESENTATION_QUESTION_STATISTIC':
      return {
        ...state, 
        items: cloneDeep(state.items).map(item => {
          if(item.id == action.data.id){
            return {...item, show_statistic: action.data.show_statistic}
          }
          return {...item};
        })
      };  
    case 'SHOW_PRESENTATION_QUESTION_ANSWER':
      return {
        ...state, 
        items: cloneDeep(state.items).map(item => {
          if(item.id == action.data.id){
            return {...item, show_answer_true: action.data.show_answer_true}
          }
          return {...item};
        })
      };  
    case 'ADD_PRESENTATION_MESSAGE':
      return {
        ...state, 
        messages: [...state.messages, action.data],
      };  
    case 'SET_PRESENTATION_PROGRESS':
      return {
        ...state, 
        slide_id: action.data.slide_id,
        start: action.data.status == 1,
        pause_date: action.data.pause_date,
        pause_time: action.data.pause_time,
      }; 
    case 'SET_PRESENTATION_ASWER_STUDENT':
      return {
        ...state,
        items: state.items.map(item => {
          if(item.id == action.slide_id){
            return {...item, question: {
              ...item.question,
              ...action.data
            }}
          }
          return {...item}
        })
      }; 
    case 'PRESENTATION_SET_SKETCH':
      return {
        ...state,
        items: state.items.map(item => {
          if(action.data.slide_id == item.id){
            return {...item, sketch: action.data.sketch}
          }
          return {...item}
        })
      }
    case 'SET_UPDATE_ANSWERS_QUESTION':
      return {
        ...state,
        items: state.items.map(item => {
          if(action.slide_id == item.id){
            return {...item, question_aswers_all: action.data}
          }
          return {...item}
        })
      }
    case 'SET_UPDATE_QUESTION_ITEM':
      return {
        ...state,
        items: state.items.map(item => {
          if(action.slide_id == item.id){
            return {...item, question: {...item.question, question: action.data}}
          }
          return {...item}
        })
      }  
    case 'PRESENTATION_CLEAR':
      return defaultState; 
    case 'SET_KEY_PRESENTATION':
      return {
        ...state,
        [action.key]: action.data
      };   
    default:
      return state
  }
}


export default presentation;