import React, { Component } from 'react';
import Switch from "react-switch";
import { connect } from 'react-redux'
import { setPresentationMode } from '../../../actions';

class PresentationMode extends Component {
  handleClick = () => {
    const { presentation_mode, setPresentationMode, session_id, presentation } = this.props;
    setPresentationMode(
      {
        session_id,
        presentation_mode: presentation_mode == 'presentation' ? 'present' : 'presentation',
        pr_id: presentation.id
      }
    )
  }

  render() {
    const { presentation_mode, hideEl } = this.props;

    if(hideEl){
      return null;
    }

    return (
      <div className="icon-item" style={{backgroundColor: presentation_mode == 'presentation' ? 'white' : 'rgb(143, 252, 141)' }} onClick={this.handleClick}>
        {presentation_mode == 'present' ? <i className="fas fa-user-graduate"></i> : <i className="fas fa-chalkboard-teacher"></i>}
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  presentation_mode: state.presentation.presentation_mode,
  session_id: state.presentation.session_id,
  presentation: state.presentation.presentation,
})

const mapDispatchToProps = {
  setPresentationMode
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(PresentationMode)
