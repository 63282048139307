import RestClient from '../../config/rest-client';
import history from '../../lib/history';
import {toastr} from 'react-redux-toastr'
import { I18n } from 'react-redux-i18n';
import moment from 'moment';


export function sendQuestion(body) {
  return async (dispatch) => {
    try {
      await RestClient.post('auth', 'socket_message', {type: 'change_question', presentation_id: body.presentation_id,  data: body})
    } catch (error) {
      
    }
  }
}

export function getPresentation(code, user_id) {
  return async (dispatch) => {
    try {
      
      const session = await RestClient.findOne('presentations', 'session', {code});

      if(session && session.id){

        let student_id = Date.now() + session.id;
        if(localStorage.getItem('sessionId') == session.id && localStorage.getItem('studentId')){
          student_id = localStorage.getItem('studentId');
        } else {
          localStorage.setItem('sessionId', session.id)
          localStorage.setItem('studentId', student_id)
        }
  
        const data = await RestClient.findOne('presentations', 'find', {code, student_id});
        if(user_id){
          dispatch({type: 'SET_PROFILE', data: {id: user_id}});
        } else {
          dispatch({type: 'SET_STUDENT_ID', data: student_id});
        }
  
        dispatch({type: 'SET_PRESENTATION', data: {...data, password: session.password}});
        return data;
      } else {
        dispatch({type: 'SET_AUTH_KEY', data: {
          show: true,
          start_date: session ? session.start_date : '',
          start_message: session ? session.start_message : '',
          name: session ? session.name : '',
          presentation_id: session ? session.presentation_id : ''
        }, key: 'start_presentation'});
        history.push('/');
      }

    } catch (error) {
      console.log("🚀 ~ file: actions.js ~ line 42 ~ return ~ error", error)
      toastr.error('Error', I18n.t('notFoundPresentation'))
    }
  }
}

export function startPresentation(session) {
  return async (dispatch) => {
    try {
      const data = await RestClient.findOne('presentations', 'start', {session});
      return true;
    } catch (error) {
      toastr.error('Error', I18n.t('notFoundPresentation'))
      history.push('/');
    }
  }
}

export function closePresentation(session) {
  return async (dispatch) => {
    try {
      dispatch({type: 'IS_BLOCKED_SPINER', data: true});
      const data = await RestClient.findOne('presentations', 'closed', {session, date: moment().format('MM-DD-YYYY h:mm a')});
      dispatch({type: 'PRESENTATION_CLEAR', data});
      dispatch({type: 'IS_BLOCKED_SPINER', data: false});
      return true;
    } catch (error) {
      toastr.error('Error', I18n.t('notFoundPresentation'))
      history.push('/');
    }
  }
}

export function setSlide(id, slide_id, is_view = false) {
  return async (dispatch) => {
    try {
      if(is_view){
        dispatch({type: 'SET_KEY_PRESENTATION', key: 'slide_id', data: slide_id});
      } else {
        dispatch({type: 'IS_BLOCKED_SPINER', data: true});
        const data = await RestClient.update('presentationprogress', id, {slide_id});
      }
      // dispatch({type: 'SET_PRESENTATION', data})
    } catch (error) {
     
    }
  }
}

export function sendMessage(body) {
  return async (dispatch) => {
    try {
      const data = await RestClient.post('presentations', 'message', body);
      // dispatch({type: 'SET_PRESENTATION', data})
    } catch (error) {
     
    }
  }
}

export function setAnswer(body, slide_id, is_view = false) {
  return async (dispatch) => {
    try {
      let data;
      if(!is_view){
        dispatch({type: 'IS_BLOCKED_SPINER', data: true});
        data = await RestClient.post('presentations', 'answer', body);
      }
      dispatch({type: 'SET_PRESENTATION_ASWER_STUDENT', data: {student_answer: body.answer, is_answer: true}, slide_id})
      return data
    } catch (error) {
     
    }
  }
}


export function isProector(status) {
  return async (dispatch) => {
    dispatch({type: 'IS_PROECTOR', data: status})
  }
}

export function setSketch(data) {
  return async (dispatch) => {
    dispatch({type: 'PRESENTATION_SET_SKETCH', data})
  }
}

export async function showStatisticQuestion(body) {
  try {
    const data = await RestClient.post('presentations', 'show_statistic_question', body);
    return data
  } catch (error) {
  }
}

export async function showAnswerQuestion(body) {
  try {
    const data = await RestClient.post('presentations', 'show_answer_question', body);
    return data
  } catch (error) {
  }
}


export function likeItem(body) {
  return async (dispatch) => {
    try {
      const data = await RestClient.post('presentations', 'like', body);
      dispatch({type: 'SET_PRESENTATION_ITEM_LIKE', data})
      return data
    } catch (error) {
     
    }
  }
}
export function getStudentAnswers(body, slide_id) {
  return async (dispatch) => {
    try {
      const data = await RestClient.post('presentations', 'student_answers', body);
      dispatch({type: 'SET_UPDATE_ANSWERS_QUESTION', data, slide_id})
      return data
    } catch (error) {
    console.log("getStudentAnswers -> error", error)
     
    }
  }
}