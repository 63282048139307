import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux'
import QRCode from 'qrcode.react';
import { I18n } from 'react-redux-i18n';
import './QRCode.scss'
import {toastr} from 'react-redux-toastr'
import { setShowModalInfo } from '../../../actions';
import SocketContext from '../../../SocketContext';

class QRCodeModal extends Component {
  state = {modal: false};

  toggle = () => {
    const {io} = this.context;
    const {presentation, is_logined, show_info} = this.props;
    this.props.setShowModalInfo(!show_info)
    if(is_logined){
      io.socket.request({
        method: 'post',
        url: '/api/auth/socket_message',
        data: {
          presentation_id: presentation.id, 
          type: 'show_info_proector',
          data: !show_info
        }
      });
    }
  }

  copy = (id = 'copy_link') => () => {
    var textBox = document.getElementById(id);
    textBox.select();
    document.execCommand("copy");
    toastr.info(I18n.t('linkInfoCopy'))
  }

  render() {
    const {modal} = this.state;
    const {presentation, show_info, profile, token_student, token_proector} = this.props;
    return (
      <React.Fragment>
        <Modal isOpen={show_info} toggle={this.toggle}>
          <ModalBody>
            <div className="QRCode">
              <h2>{I18n.t('titlePresentationInfo')}</h2>
              <hr></hr>
              <p style={{fontSize: 18}}>{presentation.name}</p>
              <p className="label">{I18n.t('linkQrCodeInfo')}</p>
              <div style={{display: 'flex'}}>
                <input type="text" className="form-control mb-2 mr-sm-2" id="copy_link" readOnly value={window.location.origin + '/presentation/' + token_student}/>
                <button type="button" onClick={this.copy('copy_link')} className="btn btn-primary mb-2"><i className="far fa-copy"></i></button>
              </div>
              {profile.id && <p className="label">{I18n.t('linkQrCodeInfoProector')}</p>}
              {profile.id && <div style={{display: 'flex'}}>
                <a id="link_proector" style={{display: 'none'}}  href={window.location.origin + '/presentation/' + token_proector} target={'_blank'}>proector</a>

                <input type="text" className="form-control mb-2 mr-sm-2" id="copy_link2" readOnly value={window.location.origin + '/presentation/' + token_proector}/>
                <button type="button" onClick={this.copy('copy_link2')} className="btn btn-primary mb-2"><i className="far fa-copy"></i></button>
                <button type="button" style={{marginLeft: 10}} onClick={() => {
                  try {
                    const h = window.window.screen.height - 400; 
                    const w = window.screen.width - 400; 
          
                    const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
                    const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);
      
                    var deviceAgent = navigator.userAgent;
                    // Set var to iOS device name or null
                    var ios = deviceAgent.toLowerCase().match(/(iphone|ipod|ipad)/);
                  
                    if (ios) {
                      const link = document.getElementById('link_proector');
                      if(link){
                        link.click();
                      }
                    } else {
                      window.open(window.location.origin + '/presentation/' + token_proector, 'proector', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+y+', left='+x);
                    }
                  } catch (error) {
                    
                  }
                }} className="btn btn-primary mb-2"><i className="fas fa-tv"></i></button>
              </div>}
              
              <p className="label">{I18n.t('labelQrCodeInfo')}</p>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <QRCode value={ window.location.origin + '/presentation/' + token_student} size={300} />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
              <Button color="secondary" onClick={this.toggle}>{I18n.t('btnCloseModalInfo')}</Button>
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  presentation: state.presentation.presentation,
  token_proector: state.presentation.token_proector,
  token_student: state.presentation.token_student,
  show_info: state.auth.show_info,
  is_logined: state.auth.is_logined,
  profile: state.auth.profile,
})

const mapDispatchToProps = {
  setShowModalInfo,
}
QRCodeModal.contextType = SocketContext;
export default connect(mapStateToProps, mapDispatchToProps)(QRCodeModal)

