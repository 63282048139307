import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux'
import { Modal, ModalBody} from 'reactstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import FieldInput from '../../../../components/Fields/FieldInput';
import { I18n } from 'react-redux-i18n';
import { required, length } from 'redux-form-validators'

class StudentName extends Component {
  constructor(props) {
    super(props);


    let show = false;

    const {presentation, session_id} = props; 

    if(session_id && presentation.check_student_name && !localStorage.getItem('session_student_name_' + session_id)){
      show = true;
    }

    this.state = {
      show
    }
  }

  submit = (values) => {
    const {session_id} = this.props; 
    localStorage.setItem('session_student_name_' + session_id, values.name);
    this.setState({show: false});
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.session_id != this.props.session_id && nextProps.presentation.check_student_name && !localStorage.getItem('session_student_name_' + nextProps.session_id)){
      this.setState({show: true});
    }
  }
  
  render() {
    const {show} = this.state;
    const {is_proector, is_logined, presentation, handleSubmit} = this.props;
    if(is_proector || is_logined || !presentation.check_student_name){
      return null;
    }
    return (
      <div className="StudentName">
        <Modal zIndex={99999} backdropClassName="ModalBG" isOpen={show} centered className={'StudentNameModal'}>
          <ModalBody>
            <form onSubmit={handleSubmit(this.submit)}>
              <Field label={I18n.t('labelStName')} validate={[required(), length({ minimum: 3 })]} component={FieldInput} name="name"/>
              <button className="btn btn-primary" style={{width: '100%'}}>{I18n.t('btnStName')}</button>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
StudentName = reduxForm({
  form: 'form_student_name',
  enableReinitialize: true,
})(StudentName);

const mapStateToProps = (state, ownProps) => ({
  is_proector: state.auth.is_proector,
  is_logined: state.auth.is_logined,
  presentation: state.presentation.presentation,
  session_id: state.presentation.session_id,
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentName);
