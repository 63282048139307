import React, { Component } from 'react';
import classNames from 'classnames';
import './StudentAnswereds.scss';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux'
import {orderBy} from 'lodash';

const charAnswer = {
  'answer_1': 'A',
  'answer_2': 'B',
  'answer_3': 'C',
  'answer_4': 'D',
  'answer_5': 'E',
  'answer_6': 'F',
  'answer_7': 'G',
  'answer_8': 'H',
  'answer_9': 'I',
  'answer_10': 'J',
}

class StudentAnswereds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
    this._Ref = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (event) => {
    if (this._Ref && this._Ref.current && this._Ref.current.contains(event.target)) {
      return;
    }
    this.setState({ active: false });
  };

  togglePopover = () => {
    this.setState(({ active }) => {
      return { active: !active };
    });
  };

  render() {

    const { items, slide_id, is_logined } = this.props;

    let tmp_items = items.map(item => ({
      ...item, 
      order_x: isNaN(parseInt(item.order_x)) ? 0 : parseInt(item.order_x)
    }));

    tmp_items = orderBy(items, ['order_x'], ['asc']);

    let slideIndex = 0;
    let slide = {};

    tmp_items.map((v, i) => {
      if(v.id == slide_id){
        slide = v;
        slideIndex = i+1;
      }
    });

    if(!slide.question_aswers_all || !is_logined) {
      return null;
    }

    return (
      <div className={classNames('StudentAnswereds')} style={{marginLeft: 20}} ref={this._Ref}>
        <div className="icon-item" onClick={this.togglePopover}>
          {I18n.t('prShowResults')}
        </div>
        {this.state.active && (
          <div className={classNames('drop-wrapper')}>
            <table boder="0">
              <tr className="item">
                <td className="text">{I18n.t('stName')}</td>
                <td className="text">{I18n.t('stSlide')}{slideIndex}</td>
              </tr>
              {slide.question_aswers_all.map(item => <tr key={item.id} className="item">
                <td className="text">{item.student_name || I18n.t('titleNotName')}</td>
                <td align="center" className={classNames("text", {
                  isTrue: item.is_true,
                  isFalse: !item.is_true,
                })}>{charAnswer[item.answer]}</td>
              </tr>)}
            </table> 
          </div>
        )}
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  items: state.presentation.items,
  slide_id: state.presentation.slide_id,
  is_logined: state.auth.is_logined,
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentAnswereds)
