import RestClient from '../../config/rest-client';

export function changeModalFeetback(data) {
  return (dispatch) => {
    dispatch({type: 'SET_MODAL_FEETBACK', data})
  }
}

export function sendFeetback(body) {
  return async (dispatch) => {
    try {
      const res = await RestClient.post('presentations', 'feedback', body);
      return true;
    } catch (error) {
      return false;
    }
  }
}