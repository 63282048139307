import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import { connect } from 'react-redux'
import LayoutBlank from './container/LayoutBlank';
import Spiner from './container/Spiner';
import SocketComponent from './SocketComponent';
import { me } from './actions';


class DefaultRouter extends Component {

  async componentDidMount(){
   this.props.me()
  }

  render() {
    const { is_loaded } = this.props;
    return (
      <Switch>
        <Route path="/" component={is_loaded ? LayoutBlank : Spiner} />
      </Switch>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  is_loaded: state.auth.is_loaded,
  is_logined: state.auth.is_logined,
})

const mapDispatchToProps = {
  me
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultRouter)

