import React, { Component } from 'react';
import Header from '../Header';
import { Switch, Route } from 'react-router';
import Login from '../Login';
import Presentation from '../Presentation';
import SocketComponent from '../../SocketComponent';
import BlockedSpiner from '../BlockedSpiner';
import PauseScreen from '../PauseScreen/PauseScreen';
import QRCodeModal from '../Header/components/QRCodeModal';
import Feetback from '../Feetback';
import ViewPresentation from '../ViewPresentation/ViewPresentation';

class LayoutBlank extends Component {
  render() {
    return (
      <React.Fragment>
        <SocketComponent>
        <PauseScreen />
        <BlockedSpiner />
        <Header />
        <QRCodeModal />
         <Feetback />
        <div className="container-fluid" id='layout'>
          <Switch>
            <Route path="/login" component={Login} />
            {/* <Route path="/presentation/proector/:id" component={(props) => <Presentation {...props} proector />} /> */}
            {/* <Route path="/presentation/teacher/:id/:user_id" component={Presentation} /> */}
            <Route path="/presentation/:id" component={Presentation} />
            <Route path="/view/:id" component={ViewPresentation} />
            <Route path="/" component={Login} />
          </Switch>
        </div>
        </SocketComponent>
      </React.Fragment>
    );
  }
}

export default LayoutBlank
