import React, { Component } from 'react';
import { connect } from 'react-redux'

class BlockedSpiner extends Component {
  render() {
    const {is_blocked_spiner} = this.props;

    if(!is_blocked_spiner){
      return null;
    }

    return (
      <div style={{
        position: 'absolute',
        zIndex: '99999',
        width: '100%',
        height: '100%',
        cursor: 'wait',
        left: 0,
        top: 0
      }} />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  is_blocked_spiner: state.auth.is_blocked_spiner
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockedSpiner);